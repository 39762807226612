<app-task-card class="vw-100 bg-info bg-gradient"  style="background-color: red;">
    <div class="card" style="width: 100%; min-width: 100%; background-color: azure; border-style: solid; border-color: black; border-width: 1px;">
     <div class="card-header">
        <h5 class="float-start" style="display: inline-block">{{_task?.taskName}}</h5>
        <h5 class="float-end" style="display: inline-block">{{_task?.amount | currency:'R'}}</h5>
     </div>
     <div class="card-body">
       <div class="row">
         <div class="col-4">
           <time datetime="2014-09-24" class="date-as-calendar inline-flex size1_25x">
             <span class="weekday">{{dayOfWeek}}</span>
             <span class="day">{{day}}</span>
             <span class="month">{{month}}</span>
             <span class="year">{{time}}</span>
           </time>
         </div>
         <div class="col-8">
           <h3 class="card-title text-left">{{_task?.taskDescription}}</h3>
           <h5 class="text-left font-weight-light">Rugby</h5>
           <h6 class="text-left font-italic"><i class="fa fa-address-book" aria-hidden="true"></i>&nbsp; Vaughn Gavin</h6>
         </div>
       </div>
       <div class="row">
         <div class="col">
           <div [innerHtml]="body" ></div>
         </div>
       </div>
       <div class="row">
         <div class="col">
           <button type="button" class="btn btn-primary" style="min-width: 100%; max-width: 100%;" (click)="onPayClick()">Pay Now</button>
         </div>
         <div class="col" *ngIf="task?.optOut">
           <button type="button" class="btn btn-success" style="min-width: 100%; max-width: 100%;" (click)="onDismissClick()">Dismiss</button>
         </div>
       </div>
     </div>
   </div> 
 </app-task-card>
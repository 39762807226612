import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProductCategoryListModel } from '@app/core/models/product/product-categorylist.model';
import { StoreService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { CartModalComponent } from '../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../modal/quick-view/quick-view.component';

@Component({
  selector: 'app-item-box',
  templateUrl: './item-box.component.html',
  styleUrls: ['./item-box.component.scss']
})
export class ItemBoxComponent implements OnInit {

  @Input() product: Product;
  @Input() vendorCategories: ProductCategoryListModel[] = [];
  @Input() currency: any = this.productService.Currency;
  @Input() cartModal: boolean = false; // Default False

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  constructor(private productService: ProductService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
  }

  navigateWithCustomData(product: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        product: product,
        categories: this.vendorCategories
      }
    };

    this.router.navigate(['/home/vendor/product/details'], navigationExtras)
      .catch(error => console.log('Navigation error:', error));
  }

  addToCart(product: any) {
    if (this.product.simpleProduct && !this.product.userDefinedPrice) {
      this.productService.addToCart(product);
    } else if (this.product.userDefinedPrice) {
      this.navigateWithCustomData(product);
    }
    else {
      this.navigateWithCustomData(product);
    }
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, delay, of, Subject, map } from 'rxjs';

import { AuthInterface } from '../types/auth/auth-interface.interface';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as  AuthActions from '@app/core/store/auth//actions';
import * as  UserActions from '@app/core/store/user//actions';

import { config } from '@app/core/app.config';
import { AppStateInterface } from '../types/appState.interface';
import { Store } from '@ngrx/store';
import { RegistrationModel } from '../models/account/registration.model';

@Injectable()
export class AuthService {

    private data: any;
    public token$ = new BehaviorSubject(null);
    public token: string;
    private loggedIn: boolean = false;
    public loginState = new Subject<boolean>();
    private authData: any = null;
    private authStrData: string = "";
    private roleData: Array<string>;
    public isGuest: boolean = false;
    public isLoggedIn: boolean = false;
    public authState: any = null;

    constructor(
        private store: Store<AppStateInterface>,
        private http: HttpClient,
        private cookieService: CookieService,
        private storageService: StorageService) {

        this.roleData = new Array<string>();

        var fakeObservable = of('dummy').pipe(delay(50));
        fakeObservable.subscribe(data => {
            this.isUserLoggedIn().subscribe(
                {
                    next: (data) => {
                        if (data == true)
                            this.loadUserData();
                        else
                            this.loginState.next(false);

                    },
                    error: (data) => {
                        this.loginState.next(false);
                    }

                }
            )
        });

    }

    ngOnInit() {
    }


    getToken() {
        return this.token;
    }

    isAuthenticated() {
        return this.storageService.get("isUserLoggedIn") === 'true';
    }

    isUserLoggedIn(): Observable<boolean> {
        return new Observable(subscriber => {
            var test = this.storageService.get("isUserLoggedIn") === 'true';
            subscriber.next(test);
        })

    }

    loadMainUser() {
        let that = this;

        return new Observable(subscriber => {
            that.storageService.set("isChildAccount", "false");
            var data: any = this.storageService.get("mainAccountToken");
            that.token = data.access_token;
            that.storageService.set("isUserLoggedIn", "true");
            that.storageService.set("token", data);
            var t = that.storageService.get("token");
            that.store.dispatch(AuthActions.loginSuccess(data));
            that.store.dispatch(UserActions.getUser());
            that.loginState.next(true);
            subscriber.next(data);
        });
    }

    loadUserData() {
        let that = this;
        this.loginState.next(true);
        var data: any = this.storageService.get("token");
        that.authData = JSON.parse(data);
        that.token = that.authData.access_token;
        that.store.dispatch(AuthActions.loginSuccess(that.authData));
        that.store.dispatch(UserActions.getUser());
        that.loginState.next(true);
    }

    logoutUser() {
        return new Observable(subscriber => {
            this.token = "";
            this.storageService.set("isUserLoggedIn", "false");
            this.storageService.set("token", '');
            subscriber.next(true);
        });
    }


    loginUser(clientId: number, userName: string, password: string, rememberMe: boolean): Observable<boolean> {
        let that = this;
        return new Observable(subscriber => {
            //const body = `grant_type=${"password"}&username=${userName}&password=${password}&clientId=${clientId}&client_secret=secret`;
            const body = `grant_type=${"password"}&username=${userName}&password=${encodeURIComponent(password)}&clientId=${clientId}`;
            const headers = new HttpHeaders(
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': "Basic " + btoa(clientId + ':' + "AllxsClient")
                });
            that.http.post<any>(config.TRIOSAPI + '/token', body, { headers: headers })
                .pipe(
                    map(response => {
                        response.data = { access_token: response.access_token }
                        return response;
                    })
                ).subscribe(
                    {
                        next: (data: any) => {
                            that.authData = data;
                            that.authStrData = JSON.stringify(data);
                            that.roleData = JSON.parse(that.authData.roles);
                            that.token = data.access_token;
                            that.storageService.set("isUserLoggedIn", "true");
                            that.storageService.set("mainAccountToken", that.authStrData);
                            that.storageService.set("token", that.authStrData);
                            var t = that.storageService.get("token");
                            that.store.dispatch(AuthActions.loginSuccess(data));
                            that.store.dispatch(UserActions.getUser());
                            that.loginState.next(true);
                            subscriber.next(data);
                        },
                        error: (errorResponse: any) => {
                            console.log(JSON.stringify(errorResponse));
                            that.loggedIn = false;
                            that.store.dispatch(AuthActions.loginFail({ data: errorResponse.error.error_description }));
                            that.loginState.next(false);
                            subscriber.error(errorResponse.error.error_description);
                        }
                    });
        })
    }

    doInternalLogin(clientId: number, userId: string) {
        let that = this;
        return new Observable<any>(subscriber => {
            that.http.post<any>(config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=internal&externalaccesstoken=' + userId, "")
                .pipe(
                    map(response => {
                        return response;
                    })
                ).subscribe(
                    {
                        next: (data: any) => {
                            that.authData = data;
                            that.authStrData = JSON.stringify(data);
                            that.token = data.access_token;
                            that.storageService.set("isUserLoggedIn", "true");
                            that.storageService.set("isChildAccount", "true");
                            that.storageService.set("token", that.authStrData);
                            var t = that.storageService.get("token");
                            that.store.dispatch(AuthActions.loginSuccess(data));
                            that.store.dispatch(UserActions.getUser());
                            that.loginState.next(true);
                            subscriber.next(data);
                        },
                        error: (errorResponse: any) => {
                            subscriber.error(errorResponse);
                        }
                    }
                );
        });

    }

    // doGoogleLogin(clientId: number) {
    //     const that = this;
    //     return new Observable(subscriber => {
    //       const provider = new firebase.auth.GoogleAuthProvider();
    //       provider.addScope('profile');
    //       provider.addScope('email');
    //       this.afAuth.auth.signInWithPopup(provider).then(
    //         res => {
    //           this.ngZone.run(() => {
    //             const result = res as any;
    //             const user = JSON.parse(JSON.stringify(result.user));
    //             //let userData = {user.}
    //             // console.log(result);
    //             var url = config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=google&externalaccesstoken=' + result.credential.accessToken;
    //             this.http.post<any>(url, user)
    //               .map(response => {
    //                 return response;
    //               })
    //               .subscribe(
    //                 data => {
    //                   if (data == null) {
    //                     return;
    //                   }

    //                   // console.log("Hello Google Login", data);
    //                   this.cookieService.set('currentToken', JSON.stringify(data));
    //                   this.cookieService.set('currentRoles', JSON.stringify(data.roles));
    //                   //localStorage.setItem('currentToken', JSON.stringify(data));
    //                   //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
    //                   that.authData = data;
    //                   that.authStrData = JSON.stringify(data);
    //                   that.roleData = that.authData.roles;
    //                   that.authState = 1;
    //                   that.isLoggedIn = true;

    //                   this.cookieService.set('currentRoles', data.roles);
    //                   that.router.navigate(["/dashboard/analytics"]);
    //                   subscriber.next(data);
    //                 },
    //                 errorResponse => {
    //                   subscriber.error({
    //                     error: errorResponse,
    //                     info: result.additionalUserInfo
    //                   });
    //                 }
    //               );
    //           });
    //         },
    //         err => {
    //           // console.log(err);
    //           subscriber.error(err);
    //         }
    //       );
    //     });
    //   }

    isUserInRole(verifyRole: string) {
        if (this.roleData.includes(verifyRole))
            return true;
        return false;
    }

    verifyEmail(token: String) {
        return new Observable(subscriber => {
            this.http.post<any>(config.TRIOSAPI + '/api/Account/VerifyEmail?token=' + token, "")
                .pipe(map(response => { return response }))
                .subscribe(
                    data => {
                        this.authData = data;
                        this.authState = 1;
                        subscriber.next(data);
                    },
                    errorResponse => {
                        this.loginState.next(false);
                        subscriber.error(errorResponse);
                    });
        });
    }


    getUserData(): Observable<AuthInterface> {

        var userData: AuthInterface = { access_token: "" };
        return of(userData).pipe(delay(3000));
    }

    register(registerData: RegistrationModel) {
        return new Observable(subscriber => {
            this.http.post<any>(config.TRIOSAPI + '/api/Account/Register', registerData)
                .subscribe({
                    next: (data: any) => {
                        this.authData = data;
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                        this.loginState.next(false);
                        subscriber.error(errorResponse);
                    }
                });
        });
    }
}
<div class="img-wrapper">
    <!-- <div class="lable-block" *ngIf="product.inventory?.quantityOnHand < 1"><span class="lable3">SOLD OUT</span></div> -->
    <div class="front">
        <a href="javascrip:void(0)" (click)="navigateWithCustomData(event)">
            <img  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="event?.eventImages[0]?.src!" class="img-fluid"
                  alt="{{ event?.eventImages[0]?.alt! }}">
        </a>
    </div>
    <div class="cart-box">
        <button (click)="navigateWithCustomData(event)">
            <h4>Get Tickets</h4>
        </button>
    </div>
</div>
<div class="product-info">
    <br/>
    <a href="javascrip:void(0)" (click)="navigateWithCustomData(event)">
    	<h5>{{ event?.eventName | titlecase }}</h5>
    </a>
    <!-- <br/> -->
    <h4>{{ formatDate(event.eventDate) }}</h4>
    <!-- <h5>{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h5> -->
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<!-- <div class="content">
    <div class="row">
        <div class="col">
            <button class="btn btn-default input-block-level">Cancel</button>
            <div class="d-grid gap-2">
                <button class="btn btn-primary" type="button">Button</button>
                <button class="btn btn-primary" type="button">Button</button>
              </div>
        </div>
    </div>
</div> -->
<div>
    <allxs-task-summary [task]="task"></allxs-task-summary>

    <div class="card-body d-flex flex-column">
        <button *ngIf="task.cashless == true" type="button" class="align-self-end btn btn-lg btn-block btn-dark"
            style="margin-top: auto;" (click)="onPayWithCashless()">Cashless</button>
        <br />
        <button *ngIf="task.onAccount == true"type="button" class="align-self-end btn btn-lg btn-block btn-dark"
            style="margin-top: auto;" (click)="onPayWithOnAccount()">On Account</button>
        <br />
        <button *ngIf="task.bankCard == true" type="button" class="align-self-end btn btn-lg btn-block btn-dark"
            style="margin-top: auto;" (click)="onPayWithBank()">Bank Card</button>
        <br />
        <button type="button" class="align-self-end btn btn-lg btn-block btn-default" 
            style="margin-top: auto;" (click)="onDismissClick()">Cancel</button>
        <br />
    </div>
</div>
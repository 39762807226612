import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductCategoryListModel } from '@app/core/models/product/product-categorylist.model';
import { EventSummary } from '@app/shared/classes/event';
import { Product } from '@app/shared/classes/product';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input() products: Product[] = [];
  @Input() events: EventSummary[] = [];
  @Input() vendorCategories: ProductCategoryListModel[] = [];
  @Input() paginate: any = {};
  @Input() layoutView: string = 'grid-view';
  @Input() sortBy: string;
  @Input() filterBy: string;

  @Output() setGrid: EventEmitter<any> = new EventEmitter<any>();
  @Output() setLayout: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortedBy: EventEmitter<any> = new EventEmitter<any>();
  @Output() filteredBy: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.setGridLayout('col-lg-2');
  }

  setGridLayout(value: string) {
    this.setGrid.emit(value);  // Set Grid Size
  }

  setLayoutView(value: string) {
    this.layoutView = value
    this.setLayout.emit(value); // Set layout view
  }

  sorting(event) {
    this.sortedBy.emit(event.target.value);
  }

  filtering(event) {
    this.filteredBy.emit(event.target.value);
  }
}


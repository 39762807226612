<ng-template class="theme-modal" #preOrderVendorModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Pre-Order Vendor Selection!</h5>
        </div>
        <div class="modal-body center">
            <h4>Where would you like to order from?</h4>
            <div *ngFor="let vendor of vendors">
                <div style="width: 100%; display: inline-block; margin-bottom: 10px;">
                    <a href="javascript:void(0)" class="btn btn-solid" style="width: 100%; display: inline-block;" (click)="selectedVendor(vendor.vendorId)">
                        {{ vendor.vendorName }}
                    </a>
                </div>
                <br>
            </div>
            
        </div>        
    </div>
</ng-template>
<ng-template class="theme-modal" #taskInfoModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Task Information!</h5>
            <button [class]="'btn btn-close'" 
            (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
          </button>
        </div>
        <div class="modal-body center">
            <div class="row">
                <div class="col">
                  <div [innerHtml]="taskBody"></div>
                </div>
              </div>
        </div>
    </div>
</ng-template>
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { EventModel } from "../models/event/event.model";
import { HttpClient } from "@angular/common/http";
import { config } from "../app.config";

@Injectable()
export class EventService {

    constructor(private http: HttpClient) {

    }


    public getEvents(clientId: number, page: number, pageSize: number): Observable<Array<EventModel>> { 
        return new Observable(subscriber => {
            let that = this;
            this.http.get<Array<EventModel>>(config.TRIOSAPI + '/api/events/getfrontendevents?clientId=' + clientId + '&vendorId=' + 0 +'&search=*' + "&page=" + page + "&pageSize=" + pageSize )
                .pipe(
                    map(response => {
                        return response;
                    })
                )
                .subscribe({
                    next: (data: Array<EventModel>) => {
                        if ((data == null) || (data == undefined))
                            return;
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                    }
                }
                );
        });
    }

    public getPublicEvents(clientId: number, page: number, pageSize: number , vendorId: number = 0): Observable<Array<EventModel>> { 
        return new Observable(subscriber => {
            let that = this;
            this.http.get<Array<EventModel>>(config.TRIOSAPI + '/api/events/getfrontendpublicevents?clientId=' + clientId + '&vendorId=' + vendorId +'&search=*' + "&page=" + page + "&pageSize=" + pageSize )
                .pipe(
                    map(response => {
                        return response;
                    })
                )
                .subscribe({
                    next: (data: Array<EventModel>) => {
                        if ((data == null) || (data == undefined))
                            return;
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                    }
                }
                );
        });
    }
}
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Storage } from '@ionic/storage-angular';
import { CookieService } from "ngx-cookie-service";
import { LocationModel } from "../models/location/location.model";

@Injectable()
export class StorageService {

    constructor(
        private cookieService: CookieService,
        private storage: Storage) {
        this.init();
    }

    async init(){
        await this.storage.create();
    }

    public getStoredLocation(): LocationModel | null{
        try {
          return JSON.parse(localStorage.get('currentLocation'));
          //return JSON.parse(localStorage.getItem('currentLocation'));
        }
        catch (ex) {
          return null;
        }
      }
    

    get(key: string){
        return this.cookieService.get(key);
    }

    /**
     * @returns value
     * @param key
     * @param value
     */
    set(key: string, value: string) {
        this.cookieService.set(key, value);
    }

    remove(key: string) {
        // return this.load().then(db => {
        //     db[key] = null
        //     delete db[key]
        //     return db
        // }).then(this.dump)
    }

     async save(db : any) {
        return await this.storage.set(environment.db, JSON.stringify(db))
    }

    async load() {
        var dbval = await this.storage.get(environment.db);
        return JSON.parse(dbval||{});
    }
}
<div class="img-wrapper">
    <div class="lable-block">
    </div>
    <div class="front">
        <a (click)="selectMenu(menu)">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                [lazyLoad]="ImageSrc" class="img-fluid lazy-loading"
                alt="{{ ImageSrc }}" />
        </a>
    </div>
    <div class="cart-info cart-wrap">
        <h4>{{ menu?.menuName | titlecase }}</h4>
    </div>
</div>
<div class="product-detail">
    <br/>
    <div>
        <a>
            <h6>{{ menu?.menuDescription | titlecase }}</h6>
        </a>
    </div>
</div>
import { createReducer, on } from "@ngrx/store";
import { AuthStateInterface } from "../../types/auth/auth-state.interface";
import * as AuthActions from "./actions";
import { AuthInterface } from "@app/core/types/auth/auth-interface.interface";

export const initialUser: AuthInterface ={
}

export const authInitialState: AuthStateInterface = {
    isAuthLoggedIn: false,
    userName: "",
    password: "",
    data: initialUser,
    error: null,
  };

export const authReducers = createReducer(
    authInitialState, 
    on(AuthActions.getAuth, (state) => ({ ...state})),
    on(AuthActions.getAuthState, (state, action) => ({ ...state, isAuthLoggedIn: action.isAuthLoggedIn})),
    on(AuthActions.loginUser, (state, action) => ({ ...state, userName: action.userName, password: action.password, error:"", data: initialUser })),    
    on(AuthActions.logoutUser, (state, action) => ({
       ...state, 
       isAuthLoggedIn: false, 
       data: initialUser })),    
    on(AuthActions.loginFail, (state, action) => ({ ...state, isLoggedIn: false, error: action.data})),    
    on(AuthActions.loginSuccess, (state, action) => ({ ...state, isAuthLoggedIn: true, userName: "", password: "", data: action.data })),    
);
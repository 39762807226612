import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { VendorModel } from '@app/core/models/vendor/vendor.model';
import { Router } from '@angular/router';
import { GetVendorAction, SelectVendorAction } from '../actions/vendor.actions';
import { VendorService } from '@app/core/services';
import { VendorStoreModel } from '@app/core/models/vendor/vendor-store.model';


export class VendorStateModel {
  public selectedVendor: VendorStoreModel;
}

@State<VendorStateModel>({
  name: 'vendor',
  defaults: {
    selectedVendor: null
  }
})

@Injectable()
export class VendorState {

    constructor(private _vendorService: VendorService,
    private _router: Router){
  }

  @Selector()
  public static selectedVendor(state: VendorStateModel) {
    return state.selectedVendor;
  }

  @Action(SelectVendorAction)
  public selectVendorAction(ctx: StateContext<VendorStateModel>, action: SelectVendorAction) {
    ctx.patchState({ selectedVendor: action.data});
    this._router.navigate(["home/vendor"]);
  }

  @Action(GetVendorAction)
  public getVendorAction(ctx: StateContext<VendorStateModel>, action: GetVendorAction) {
    return this._vendorService.getVendorById(action.vendorId).subscribe(data => {
      ctx.patchState({ selectedVendor: data});
      this._router.navigate(["home/vendor"]);
    })  
  }

}

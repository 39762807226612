import { WebOrderItemModel } from "./weborder-item.model";

export class WebOrderModel {
    public vendorId: number;
    public userId: string;
    public orderDate: Date;
    public orderTotal: number;
    public instructions: string;
    public items: Array<WebOrderItemModel>;
    public topupNotifyURL: string;
    public sessionId: string;
}
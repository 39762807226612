import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuModel } from '@app/core/models/preorder/menu.model';
import { Product } from '@app/shared/classes/product';
import { ProductService } from '@app/shared/services/product.service';
import { Select, Store as ngxStore } from '@ngxs/store';
import { SelectPreOrderMenuAction } from 'src/@allxs/store/actions/preorder.action';

@Component({
  selector: 'app-menu-item-box',
  templateUrl: './menu-item-box.component.html',
  styleUrls: ['./menu-item-box.component.scss']
})
export class MenuItemBoxComponent implements OnInit {

  @Input() menu: MenuModel;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() userId: string;
  

  public ImageSrc : string = "assets/images/preorders/menu.jpg";

  constructor(private productService: ProductService,
    private _ngxStore: ngxStore,) { }

  selectMenu(menu) {
    this._ngxStore.dispatch(new SelectPreOrderMenuAction(menu));
  }

  ngOnInit(): void {

  }

}


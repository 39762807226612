import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router) {

        if (this.authService.isAuthenticated()) {
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (!this.authService.isAuthenticated()) {
            this.router.navigate(["home/auth/login"]);
            return false;
        }

        var role = route.paramMap.get('role');
        if (role === null) {
            return true;
        }
        console.log("canAvtivate false");
        return true;
    }


}

import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../wallet/wallet-dashboard/wallet-dashboard.module').then(m => m.WalletDashboardModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('../../wallet/reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: "Reports"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../wallet/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
];
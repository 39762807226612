import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '@app/core/services';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from 'express';
import { Select, Store as ngxStore } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TaskInterface } from 'src/@allxs/interface/task.interface';
import * as moment from 'moment';
import { TaskInformationModalComponent } from '../modal/task-information-modal/task-information-modal.component';
import { TaskPaymentModalComponent } from '../modal/task-payment-modal/task-payment-modal.component';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

  @Input() task: TaskInterface;
  @Input() paymentModal: boolean;

  @ViewChild("taskInfoModal") TaskInfoModal: TaskInformationModalComponent;
  @ViewChild("taskPaymentModal") TaskPaymentModal: TaskPaymentModalComponent;

  public taskDeadline: string;
  public dayOfWeek: string = "...";
  public day: string = "...";
  public month: string = "...";
  public time: string = "...";

  constructor() {
  }

  ngOnInit() {
  }

  getTaskDeadline(taskDeadline: string) {
    let dt = moment(moment.utc(taskDeadline).toDate())
    //.format("DD MMM YYYY HH:mm")
    this.dayOfWeek = dt.format("dddd");
    this.day = dt.format("DD");
    this.month = dt.format("MMM");
    this.time = dt.format("HH:mm A");

    return this.dayOfWeek + ' ' + this.day + ' ' + this.month + ", " + this.time;
  }

  moreTaskInformation(taskBody: string) {
    this.TaskInfoModal.openModal(taskBody);
  }

  onPayment(task) {
    this.TaskPaymentModal.openModal(task);
  }

}

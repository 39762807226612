import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
import { cloneDeep } from "lodash-es";
import { TaskService } from 'src/@allxs/services';
import { CheckForTasksAction, CheckTaskAction, DismissTaskAction, HideTaskAction, ShowTaskAction } from '../actions/task.action';
import { TaskInterface, TasksInterface } from 'src/@allxs/interface/task.interface';

export class TaskStateModel {
  tasks = {
    data: [] as TaskInterface[],
    total: 0
  }
  selectedTask: TaskInterface | null
  status: boolean
}

@State<TaskStateModel>({
  name: 'task',
  defaults: {
    tasks: {
      data: [],
      total: 0
    },
    selectedTask: null,
    status: false
  }
})

@Injectable()
export class TaskState {


  constructor(private _taskService: TaskService,
    private _router: Router) {

  }


  @Selector()
  public static status(state: TaskStateModel) {
    return state.status;
  }

  @Selector()
  public static tasks(state: TaskStateModel) {
    return state.tasks.data;
  }

  @Action(ShowTaskAction)
  public showTaskAction(
    ctx: StateContext<TaskStateModel>, action: ShowTaskAction) {
    ctx.patchState({ status: true });
  }

  @Action(HideTaskAction)
  public hideStateAction(ctx: StateContext<TaskStateModel>) {
    ctx.patchState({ status: false });
  }


  @Action(CheckForTasksAction)
  public checkForTasksAction(
    ctx: StateContext<TaskStateModel>, action: CheckForTasksAction) {
    this._taskService.checkForTask().pipe(
      tap({
        next: (result: TasksInterface) => {
          ctx.patchState({
            tasks: {
              data: result.data,
              total: result?.total ? result?.total : result?.data.length
            }
          })
        },
        error: (errorResponse: any) => {

        }
      })
    ).subscribe();
  }

  @Action(CheckTaskAction)
  public checkTaskAction(
    ctx: StateContext<TaskStateModel>, action: CheckTaskAction) {
    this._taskService.checkForTask().pipe(
      tap({
        next: (result: TasksInterface) => {
          ctx.patchState({
            tasks: {
              data: result.data,
              total: result?.total ? result?.total : result?.data.length
            }
          })
        },
        error: (errorResponse: any) => {

        }
      })
    ).subscribe();
  }


  @Action(DismissTaskAction)
  public dismissTaskAction(
    ctx: StateContext<TaskStateModel>, action: DismissTaskAction) {
    const myTasks = cloneDeep(ctx.getState());
    var tasksLeft = [];
    let atask = myTasks.tasks.data.find(x => x.taskId == action.task.taskId);
    let index = myTasks.tasks.data.indexOf(atask);
    if (atask) {
      myTasks.tasks.data.splice(index, 1);
    }
    ctx.patchState({
      tasks: {
        data: myTasks.tasks.data,
        total: myTasks.tasks.data?.length ? myTasks.tasks.data.length:0
      }
    });
  }

}
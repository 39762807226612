import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '@app/shared/classes/product';
import { NewProductSlider } from '@app/shared/data/slider';
import { ProductService } from '@app/shared/services/product.service';

@Component({
  selector: 'app-event-dates-vertical-slider',
  templateUrl: './event-dates-vertical-slider.component.html',
  styleUrls: ['./event-dates-vertical-slider.component.scss']
})
export class EventDatesVerticalSliderComponent implements OnInit {

  public title: string = 'Available Dates';
  @Output() ticketSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() tickets : Product[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService) { 
  }

  ngOnInit(): void {
    if (this.tickets.length === 1) {
      this.selectTicket(this.tickets[0]); // Select the first ticket by default
    }
  }

  selectTicket(ticket: any) {
    this.ticketSelected.emit(ticket); 
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = '';// Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() companyName: string = 'Allxs Media Pty Ltd.';
  @Input() contactUsEmail: string = 'support@allxsmedia.com';
  @Input() footerDescription: string = `Allxs Media Pty Ltd is a leading Fintech company that specializes in providing Africa's top-notch experience management platform.`;
  @Input() whatsAppContact: string = '+27(0) 66 472 8657'

  public today: number = Date.now();

  constructor() { }

  ngOnInit(): void {
  }

}
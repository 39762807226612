import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { TaskInterface } from 'src/@allxs/interface/task.interface';

@Component({
    selector     : 'allxs-task-summary',
    templateUrl  : './tasksummary.component.html',
    styleUrls    : ['./tasksummary.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskSummaryComponent implements OnChanges
{
    @Input() task: TaskInterface;

    public taskDeadline: string;
    public dayOfWeek: string = "...";
    public day: string = "...";
    public month: string = "...";
    public time: string = "...";
    
    /**
     * Constructor
     */
    constructor()
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Expanded
    
    }

    getTaskDeadline(taskDeadline: string) {
        let dt = moment(moment.utc(taskDeadline).toDate())
        //.format("DD MMM YYYY HH:mm")
        this.dayOfWeek = dt.format("dddd");
        this.day = dt.format("DD");
        this.month = dt.format("MMM");
        this.time = dt.format("HH:mm A");
    
        return this.dayOfWeek + ' ' + this.day + ' ' + this.month + ", " + this.time;
      }
}

<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="header-contact">
              <ul>
                <li>Powered by Allxs VIP</li>
                <li><i class="fa fa-phone" aria-hidden="true"></i>WhatsApp Support: +27 66 472 8657</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-end">
            <ul class="header-dropdown">
              <!-- <li class="compare">
                <a [routerLink]="['/shop/compare']"><i class="" aria-hidden="true"></i></a>
              </li> -->
              <li class="mobile-wishlist">
                <a [routerLink]="['/shop/wishlist']"><i class="" aria-hidden="true"></i></a>
              </li>
              <li class="onhover-dropdown mobile-account">
                <i class="fa fa-user" aria-hidden="true"></i>My Account
                <ul class="onhover-show-div">
                  <li>
                    <a data-lng="en" [routerLink]="['/home/account/wishlist']">
                      Wishlist
                    </a>      
                  </li>
                  <li>
                    <a data-lng="en" [routerLink]="['/wallet/dashboard/landing']">
                      Wallet
                    </a>   
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <div class="brand-logo">
                <a href="javascript:void(0)" (click)="onHome()" id="headerlogo">
                  <img [src]="themeLogo" class="img-fluid" alt="theme-logo">
                </a>
              </div>
            </div>
            <div class="menu-right pull-right">
              <div>
                <app-menu></app-menu>
              </div>
              <div>
                <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>  
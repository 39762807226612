import { VendorStoreModel } from "@app/core/models/vendor/vendor-store.model";

export class SelectVendorAction {
    static readonly type = '[Vendor] Select';
    constructor(public data : VendorStoreModel) {}
 }
  

 export class GetVendorAction {
    static readonly type = '[Vendor] Get by Id';
    constructor(public vendorId: number) {}
 }

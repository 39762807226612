<div class="card order-graph sales-carousel">
    <div class="card-header">
        <h4>{{ task?.taskName }}</h4>
    </div>
    <div class="card-body">
        <div class="d-flex align-items-start">
            <div class="media-body">
                <span>Vendor Name</span>
                <h2 class="mb-0">{{ task?.amount | currency:'R'}}</h2>
                <h5 class="f-w-600">Due Date: {{ getTaskDeadline(task.deadline) }}</h5>
                <p>{{ task?.taskDescription }} </p>
            </div>
            <div class="bg-primary b-r-8">
                <div class="small-box" *ngIf="paymentModal">
                    <i class="fa fa-credit-card-alt fa-1x" aria-hidden="true"></i>
                </div>
                <div class="small-box" (click)="moreTaskInformation(task?.body)" *ngIf="!paymentModal">
                    <i class="fa fa-info-circle fa-2x" aria-hidden="true"></i>
                  </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col">
                <button type="button" class="btn btn-primary" style="min-width: 100%; max-width: 100%;" (click)="onPayment(task)">Pay Now</button>
            </div>
            <br />
            <div class="col" *ngIf="task?.optOut">
                <button type="button" class="btn btn-success" style="min-width: 100%; max-width: 100%;">Close</button>
            </div>
        </div>
    </div>
</div>

<app-task-information-modal #taskInfoModal></app-task-information-modal>
<app-task-payment-modal #taskPaymentModal></app-task-payment-modal>
import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from './windows.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badgeType?: string;
	badgeValue?: string;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public isLoggedIn = false;
	public isVendorLanding = false;

	public isChildAccount = false;

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'home',
			type: 'sub',
			active: false,
			path: this.isVendorLanding ? '/home/vendor' : '',
			children: []
		},
		{
			title: 'about us',
			type: 'sub',
			active: false,
			path: '',
			children: []
		},
		...(this.isLoggedIn ? [
			{
				title: 'support',
				type: 'sub',
				active: false,
				path: '',
				children: []
			}
		] : []),
		{
			title: this.isLoggedIn ? 'log out' : 'log in',
			type: 'sub',
			active: false,
			path: this.isLoggedIn ? '/home/auth/logout' : '/home/auth/login',
			children: []
		},
	];
	

	WALLETMENUITEMS: Menu[] = [
		{
			path: '/wallet/dashboard/landing', title: 'Wallet', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'My Account', icon: 'box', type: 'sub', active: false, children: [
				{
					path: '/wallet/reports/orders', title: 'Orders', type: 'link'
				},
				{
					path: '/wallet/reports/transaction-report', title: 'Transactions', type: 'link'
				}
			]
		},
		{
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/wallet/settings/profile', title: 'Profile', type: 'link' },
			]
		},
		{
			title: 'Log Out', path: '/auth/login', icon: 'log-in', type: 'link', active: false
		}
	]

	ADMINMENUITEMS: Menu[] = [
		{
			path: '/wallet/dashboard/landing', title: 'Wallet', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Client Admin', icon: 'box', type: 'sub', active: false, children: [
				{
					path: '/admin/client-admin/vendor-admin', title: 'Vendors', type: 'link'
				}
			]
		},
		{
			title: 'Log Out', path: '/auth/login', icon: 'log-in', type: 'link', active: false
		}
	]

	// Array
	menuItems = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	updateMenuItems(isLoggedIn: boolean, isVendorLanding: boolean): void {
		this.isLoggedIn = isLoggedIn;
		this.isVendorLanding = isVendorLanding;

		this.MENUITEMS = [
			{
				title: 'home', type: 'sub', active: false, 
				path: this.isVendorLanding ? '/home/vendor' : '', 
				children: []
			},
			{
				title: 'about us', type: 'sub', active: false, path: '', children: [
				]
			},
			...(this.isLoggedIn ? [
				{
					title: 'support',
					type: 'sub',
					active: false,
					path: '',
					children: []
				}
			] : []),
			{
				title: this.isLoggedIn ? 'log out' : 'log in',
				type: 'sub',
				active: false,
				path: this.isLoggedIn ? '/home/auth/logout' : '/home/auth/login',
				children: []
			},
		];

		this.menuItems.next(this.MENUITEMS);
	}

	items = new BehaviorSubject<Menu[]>(this.WALLETMENUITEMS);

	updateWalletMenuItems(isChildAccount: boolean): void {
		this.isChildAccount = isChildAccount;

		this.WALLETMENUITEMS = [
			{
				path: '/wallet/dashboard/landing', title: 'Wallet', icon: 'home', type: 'link', badgeType: 'primary', active: false
			},
			{
				title: 'My Account', icon: 'box', type: 'sub', active: false, children: [
					{
						path: '/wallet/reports/orders', title: 'Orders', type: 'link'
					},
					{
						path: '/wallet/reports/transaction-report', title: 'Transactions', type: 'link'
					}
				]
			},
			{
				title: 'Settings', icon: 'settings', type: 'sub', children: [
					{ path: '/wallet/settings/profile', title: 'Profile', type: 'link' },
				]
			},
			{
				title: this.isChildAccount ? 'Leave Child Account' : 'Log Out',
				icon: 'log-in',
				type: 'sub',
				active: false
			}
		];

		this.items.next(this.WALLETMENUITEMS);
	}

	adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENUITEMS);
}

import { CashoutModel } from '../cashout/cashout.model';
import { PortalModel } from './portal.model';
import { ClientPermissionsModel } from './client-permissions.model';
import { ClientMerchantModel } from './clientmerchant.model';
import { ClientEconomyModel } from './client.economymodel';
import { SplashScreenModel } from './splashscreen.model';
import { QueueConfigModel } from './queueconfig.model';
import { ClientVersionModel } from './clientversion.model';
import { ClientVendorTheme } from './clienttheme.model';

export class  ClientModel {   
    public clientId: number;
    public accountOwnerId: number;
    public accountOwnerName: number;
    public analyticsCode: string;
    public clientName: string;
    public clientLogo: string;
    public basicAccount: number;
    public axsAccount: number;
    public fromEmail: string;
    public portalURL: string;
    public onlineStoreEnabled: boolean;
    public childOTPPinRequired: boolean;
    public oTPPinRequired: boolean;
    public emailVerified: boolean;
    public clientLBAEnabled: boolean;
    public covid19Data: boolean;
    public covid19PersonalData: boolean;
    public dietaryRestrictionsEnabled: boolean;
    public dietaryRestrictionType: string;

    public unlinkAssociations: boolean;
    public socialLogin: boolean;
    public adLogin: boolean;
    public whatsappNumber: string;
    public manualURL: string;
    public dashboardStoreLabel: string;
    public dashboardPreorderLabel: string;
    public registrationType: string;
    public registrationAutoLogin: boolean;
    public claimAccount: boolean;
    public portal: PortalModel;    
    public createDate: any;
    public accounts: number;
    public productionVersion: string;
    public testVersion: string;
    public clientRegistration: boolean;
    public clientDefaultEmailValid: boolean;
    public maxChildAccounts: number;
    public allowAddChildAccount: boolean;
    public allowGotoAccount: boolean;
    public preorderType: string;
    public preorderEnabled: boolean;
    public allowNegativeBalance: boolean;
    public deliveryToolbar: boolean;
    public startPage: string;
    public behindAuthWall: boolean;

    public permissions: ClientPermissionsModel;
    public merchant: ClientMerchantModel;
    public economy: ClientEconomyModel;
    public cashout: CashoutModel;
    public splashScreen: SplashScreenModel;
    public queueConfig: QueueConfigModel;
    public versions: ClientVersionModel;
    public theme: ClientVendorTheme;
    public limitReports: Number;
    
    constructor(){
        //this.permissions = new ClientPermissionsModel();
    }
}
import { Component,ElementRef,EventEmitter,Input,Output,ViewChild,ViewEncapsulation } from '@angular/core';
import { TaskInterface } from 'src/@allxs/interface/task.interface';
import * as moment from 'moment';

@Component({
    selector     : 'app-task',
    templateUrl  : './task.component.html',
    styleUrls    : ['./task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'task',
})
export class TaskComponent 
{
    public _task: TaskInterface;
    public body: string = "<p>Hello</p>";
    public dayOfWeek: string = "..."
    public day: string = "..."
    public month: string = "..."
    public time: string = "..." 
    public deadline: string;

    @Input('task') 
    get task():  TaskInterface {
        return this._task;
    }
    set task(value: TaskInterface) {
        this._task = value;
        this._task.taskDescription??"Hello";
        this.body = this._task.body;
        let dt =  moment(moment.utc(this._task.deadline).toDate())
        //.format("DD MMM YYYY HH:mm")
        this.dayOfWeek = dt.format("dddd");
        this.day = dt.format("DD");
        this.month = dt.format("MMM");
        this.time = dt.format("HH:mm A")
    }

    @Output('onDismiss')  onDismiss = new EventEmitter<TaskInterface>();
    @Output('onPay')  onPay  = new EventEmitter<TaskInterface>();
    
    @ViewChild("content", { static: false }) content: ElementRef;
    //@Select(TaskState.tasks) tasks$: Observable<TaskModel>;
    constructor()
    {

    }
    
    onDismissClick(){
        this.onDismiss.emit(this.task);
    }

    onPayClick(){
        this.onPay.emit(this.task);
    }

}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, of } from "rxjs";
import * as TransactionActions from './actions';
import { AppStateInterface } from "@app/core/types/appState.interface";
import { Store } from "@ngrx/store";
import { TransactionService } from "@app/core/services";


@Injectable()
export class TransactionEffects {


    getStatementofBalance$ = createEffect(() => this.actions$.pipe(
        ofType(TransactionActions.getStatementofBalance),
        mergeMap((action) => {
            return this.transactionService.getStatementofBalance(action.accountId, action.fromDate, action.toDate).pipe(
                map((data) => {
                    return TransactionActions.getStatementofBalanceSuccess({ data })
                }
                ))
        }))
    );

    constructor(
        private store: Store<AppStateInterface>,
        private actions$: Actions,
        private transactionService: TransactionService) { }
}
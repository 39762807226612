import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from "@angular/core";
import { Subscription, BehaviorSubject, Observable, delay, of, Subject, map } from 'rxjs';
import { UserModel } from "../models/user/user.model";
import { config } from "../app.config";
import { DietModel } from '../models/user/diet.model';
import { CookieService } from 'ngx-cookie-service';
import { RulesService } from './rules.service';
import { UserOTPRequestModel, UserOTPResponseModel, UserOTPUpdateModel } from '../models/user/userotp-request.model';
import { AuthService } from './auth.service';
import { Store as ngxStore } from '@ngxs/store';
import { LoadUserAction } from 'src/@allxs/store/actions/user.action';

@Injectable()
export class UserService implements OnDestroy {

    private subscription: Subscription;
    private _isLoggedIn: boolean = false;
    private userId: string;
    private userData: UserModel;
    private hasStudentVenture = false;
    private hasUser = false;
    private userSubject = new BehaviorSubject<UserModel>(null);

    private hasLinkedAccounts = false;

    constructor(private http: HttpClient,
        private authService: AuthService,
        private cookieService: CookieService,
        private _ngxStore: ngxStore,
        private rulesService: RulesService) {
        this.subscription = new Subscription();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public fetchUser(): Observable<UserModel> {
        return new Observable<UserModel>(subscriber => {
            let that = this;
            var urlString = "/api/users/userinfo?clientId=" + 349;
            this.http.get<UserModel>(config.TRIOSAPI + urlString)
                .pipe(
                    map(response => {
                        if (response.userPhoto == null)
                            response.userPhoto = 'assets/img/demo/64x64.png';
                        return response;
                    })
                )
                .subscribe({
                    next: (data: any) => {
                        if ((data == null) || (data == undefined))
                            return;
                        that.userId = data.userId;
                        that.userData = data;
                        that._ngxStore.dispatch(new LoadUserAction(that.userData));
                        subscriber.next(that.userData);

                        //   //that.userSubject.next(data);

                        //   that.subscribe();

                        //   if (that.userData.linkedAccounts > 0) {
                        //     that.fetchLinkedAccounts();
                        //   }
                        //   else {
                        //     that.userLoaded = true;
                        //   }
                        //   if (that.userData.parentAccounts > 0)
                        //     that.fetchParentAccounts();

                        //   this.store.dispatch(new LoginAction(data));
                        //   that.initServices();
                        //   that.rulesService.setUserRules(data);

                        //   if ((that.clientData.splashScreen == null) || (that.clientData.splashScreen.splash == null)) {
                        //     that.checkDocuments(navigate);
                        //   }

                        //   if (gotodash)
                        //      this.router.navigate(["/dashboard/analytics"]);
                    },
                    error: (errorResponse: any) => {
                        errorResponse => {
                          that.hasUser = false;
                          that.userSubject.error(errorResponse);
                          //that.authService.logout();
                    }
                }
                });
        });

        //this.registerFCM();
    }

    public fetchDietInformation(accountId: string): Observable<DietModel> {
        return new Observable<DietModel>(subscriber => {
            this.http.get<DietModel>(config.TRIOSAPI + '/api/users/diet?accountId=' + accountId)
                .subscribe({
                    next: (data: any) => {
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                        subscriber.error(errorResponse);
                    }
                }
                );
        });
    }

    public fetchLinkedAccounts(): Observable<Array<UserModel>> {
        return new Observable<Array<UserModel>>(subscriber => {
            this.http.get<Array<UserModel>>(config.TRIOSAPI + '/api/users/loadLinkedAccounts')
                .subscribe({
                    next: (data: any) => {
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                        subscriber.error(errorResponse);
                    }
                }
                );
        });
    }

    public transferFunds(accountGuid: string, amount: number, transferType: string, note: string): Observable<any> {
        return new Observable<any>(subscriber => {
            this.http.post<any>(config.TRIOSAPI + `/api/users/transferFunds?toAccount=${accountGuid}&amount=${amount}&transferType=${transferType}&note=${note}`, null)
                .subscribe({
                    next: (data: any) => {
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                        subscriber.error(errorResponse);
                    }
                }
                );
        });
    }

    public getTempToken() {
        var urlString = "/api/users/getTempToken";
        return new Observable<any>(subscriber => {

            this.http.get(config.TRIOSAPI + urlString)
                .pipe(
                    map(response => {
                        return response;
                    })
                )
                .subscribe({
                    next: (data: any) => {
                        subscriber.next(data);
                    },
                    error: (response: any) => {
                        subscriber.error(response);
                    }
                });
        });
    }

    public fetchDiet(accountId: string) {
        var urlString = "/api/users/diet?accountId=" + accountId;
        return this.http
            .get<DietModel>(config.TRIOSAPI + urlString)
    }

    public updateUserReg(data: UserModel) {
        var urlString = "/api/users/updateUserReg";
        return new Observable<any>(subscriber => {

            this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
                .pipe(
                    map(response => {
                        if (response != null) {
                            if (response.userPhoto == null)
                                response.userPhoto = 'assets/img/demo/64x64.png';
                        }
                        return response;
                    })
                )
                .subscribe(
                    data => {
                        if ((data == null) || (data == undefined))
                            return;
                        this.userData = data;
                        this.userSubject.next(data);
                        this.userId = data.userId;
                        subscriber.next(data);
                    },
                    response => {
                        subscriber.error(response);
                    }
                );
        });
    }

    public updateUserRegCompass(data: UserModel) {
        var urlString = "/api/users/updateUserRegCompass";
        return new Observable<any>(subscriber => {

            this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
                .pipe(
                    map(response => {
                        if (response != null) {
                            if (response.userPhoto == null)
                                response.userPhoto = 'assets/img/demo/64x64.png';
                        }
                        return response;
                    })
                )
                .subscribe(
                    data => {
                        if ((data == null) || (data == undefined))
                            return;
                        this.userData = data;
                        this.userSubject.next(data);
                        this.userId = data.userId;
                        subscriber.next(data);
                    },
                    response => {
                        subscriber.error(response);
                    }
                );
        });
    }

    public getAccount(findUserId: string): UserModel {
        if (this.hasLinkedAccounts && (this.userData != null) && (this.userData != undefined)) {
            if (this.userData.userId == findUserId) {
                return this.userData;
            } else {
                return this.userData.linkedAccountsData.find(linkedUser => {
                    return linkedUser.userId == findUserId
                });
            }
        }
        return null;
    }

    public requestUserOTP(userOtpRequest: UserOTPRequestModel) {
        var urlString = "/api/users/otpPin";
        return new Observable<UserOTPResponseModel>(subscriber => {
            this.http.post<any>(config.TRIOSAPI + urlString, userOtpRequest)
                .subscribe({
                    next: (data: any) => {
                        subscriber.next(data);
                    },
                    error: (errorResponse: any) => {
                        subscriber.error(errorResponse);
                    }
                })
        });
    }

    public switchToMainAccount() {
        let that = this;
        var testToken = this.cookieService.get('addAccountToken');
        var testRoles = this.cookieService.get('addAccountRoles');
        this.cookieService.set('currentToken', testToken);
        this.cookieService.set('currentRoles', testRoles);
        this.cookieService.delete('addAccountToken');
        this.cookieService.delete('addAccountRoles');
        this.cookieService.delete('addAccountType');
        that.hasUser = false;
        that.hasStudentVenture = false;
        that.fetchUser();
        this.rulesService.clearRules();
        window.location.reload();
    }

    public switchToChildAccount(clientId: number, userId: string, fromType: string) {
        let that = this;
        return new Observable<UserModel>(subscriber => {
            this.cookieService.delete('parentAccountType');
            this.cookieService.delete('parentAccountToken');
            this.cookieService.delete('parentAccountRoles');
            var testToken = this.cookieService.get('currentToken');
            var testRoles = this.cookieService.get('currentRoles');
            if ((testToken == null) || (testToken.length == 0)) {
                // testToken = this.authService.getStrToken();
            }
            this.cookieService.set('parentAccountToken', testToken);
            this.cookieService.set('parentAccountRoles', testRoles);
            this.cookieService.set('parentAccountType', fromType);
            this.cookieService.getAll();

            this.hasUser = false;
            this.authService.doInternalLogin(clientId, userId)
                .subscribe({
                    next: (data: any) => {
                        console.log("Switch Child Complete");
                        this.fetchUser();
                        this.rulesService.clearRules();
                        window.location.reload();
                    },
                    error: (errorResponse: any) => {
                        this.rulesService.clearRules();
                        that.switchToParentAccount();
                    }
                });
        });
    }

    public switchToParentAccount() {
        let that = this;

        return new Observable<UserModel>(subscriber => {
            var testToken = this.cookieService.get('parentAccountToken');
            var testRoles = this.cookieService.get('parentAccountRoles');
            var testAccountType = this.cookieService.get('parentAccountType');
            this.cookieService.delete('parentAccountToken');
            this.cookieService.delete('parentAccountRoles');
            this.cookieService.delete('parentAccountType');
            this.cookieService.set('currentToken', testToken);
            this.cookieService.set('currentRoles', testRoles);
            this.cookieService.getAll();

            this.hasUser = false;
            that.hasUser = false;
            this.authService.loadMainUser()
                .subscribe({
                    next: (data: any) => {
                        console.log("Switch Parent Complete");
                        this.fetchUser();
                        this.rulesService.clearRules();
                        window.location.reload();
                    },
                    error: (errorResponse: any) => {
                        console.log(errorResponse);
                        this.rulesService.clearRules();
                    }
                });
        });

    }

    public setUserPhone(phoneNumber: string): Observable<UserOTPUpdateModel> {
        return this.http.post<UserOTPUpdateModel>(config.TRIOSAPI + '/api/users/updateuserphone?phoneNumber=' + phoneNumber, null);
    }

    public setUserOTP(token: string, pin: string): Observable<UserModel> {
        return this.http.post<UserModel>(config.TRIOSAPI + '/api/users/completeupdateuserphone?token=' + token + "&pin=" + pin, null);
    }

}
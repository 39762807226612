import { Component, EventEmitter, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { VendorOrderResponseModel } from '@app/core/models/cart/vendor-order-response.model';
import { ClientService, DataService, StoreService } from '@app/core/services';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { ProductService } from '@app/shared/services/product.service';
import { environment } from '@env/environment';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { List } from 'immutable';

@Component({
  selector: 'app-paygate-modal',
  templateUrl: './paygate-modal.component.html',
  styleUrls: ['./paygate-modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PaygateModalComponent {

  public active = 'select';
  public closeResult: string;
  public modalOpen: boolean = false;
  public spinner
  updateTimer: any = null;
  updateTimerSubscription: Subscription;
  modal: NgbModalRef;

  public vendorOrderResponse: List<VendorOrderResponseModel>;
  progressMessage: string = "";
  public orderNumber: number = 0;

  @ViewChild("paygateModal", { static: false }) PayGateModal: TemplateRef<string>;

  @Output() paygateSuccess: EventEmitter<any> = new EventEmitter();
  @Output() paygateFail: EventEmitter<any> = new EventEmitter();

  constructor(
    private storeService: StoreService,
    public clientService: ClientService,
    private dataService: DataService,
    public productService: ProductService,
    private modalService: NgbModal) {
  }

  async openModal(requestId: any, checksum: any, order: any) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.PayGateModal, {
      ariaLabelledBy: 'PayGate-Modal',
      centered: true,
      size: 'paygatesize',
      windowClass: 'paygate-modal',
      backdrop: 'static'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.postToPaygate(requestId, checksum);
    this.startUpdateTimer(order);
  }

  postToPaygate(requestId: any, checksum: any) {
    this.post('https://secure.paygate.co.za/payweb3/process.trans',
      {
        PAY_REQUEST_ID: requestId,
        CHECKSUM: checksum
      });
  }


  post(path: any, parameters: any) {
    var form = $('<form id="paygatetestform" target="paygatepayreceiver" action="url-to-server-Page"></form>');
    form.attr("method", "post");
    form.attr("action", path);
    $.each(parameters, function (key, value) {
      var field = $('<input></input>');
      field.attr("type", "hidden");
      field.attr("name", key.toString());
      field.attr("value", value);
      form.append(field);
    });
    try {
      $("#paygatepayreceiver").remove('#paygatetestform');
    } catch (e) {
      // console.log(e);
    }
    $("#paygatepayreceiver").append(form);
    form.submit();
  }

  public startUpdateTimer(contract: string) {
    let that = this;
    if (this.updateTimer == null) {
      this.updateTimer = timer(6000, 6000);
      this.updateTimerSubscription = this.updateTimer.subscribe((x: any) => {
        that.clientService.getClientTopupStatus(contract)
          .subscribe({
            next: (data: any) => {
              var resultData = JSON.parse(data);

              if (resultData.Result == "wait") {
                return;
              }

              if (resultData.Result == "OK") {
                that.dataService.lastStoreOrder = resultData.OrderResponse;
                that.progressMessage = "Bank response!<br><p class='label-success txt-color-white'><b>" + resultData.Description + "</b></p>";
                that.orderNumber = resultData.Description;

                that.vendorOrderResponse = resultData.OrderResponse.vendorResponse
                this.onComplete(that.vendorOrderResponse);
                that.storeService.emptyCart();

              }
              else {
                that.progressMessage = "Bank response!<br><p class='label-danger txt-color-white'><b>" + resultData.Description + "</b></p>";
                this.onFail(that.progressMessage);
              }
              this.modal.dismiss('close');
              that.closeTimer();
            },
            error: (errorResponse: any) => {

            }
          });
      });
    }
  }

  closeTimer() {
    var _closeTimer = timer(3000, 0);
    var _subscription = _closeTimer.subscribe(x => {
      _subscription.unsubscribe();
    });
    this.updateTimer = null;
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.updateTimerSubscription)
      this.updateTimerSubscription.unsubscribe();
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  onComplete(result: any) {
    this.updateTimerSubscription.unsubscribe();
    this.updateTimer = null;
    this.paygateSuccess.emit(result);
    this.modal.dismiss('close');
  }

  onFail(result: any) {
    this.updateTimerSubscription.unsubscribe();
    this.updateTimer = null;
    this.paygateFail.emit(result);
    this.modal.dismiss('close');
  }

}

import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { VoucherModel } from '@app/core/models/voucher/voucher.model';
import { VoucherService } from '@app/core/services';
import { EventSummary } from '@app/shared/classes/event';
import { Product } from '@app/shared/classes/product';
import { ProductService } from '@app/shared/services/product.service';

@Component({
  selector: 'app-ticket-item-box',
  templateUrl: './ticket-item-box.component.html',
  styleUrls: ['./ticket-item-box.component.scss']
})
export class TicketItemBoxComponent {

  @Input() ticket: VoucherModel;

  public eventDate: string;
  
  constructor(private productService: ProductService, 
    private voucherService: VoucherService,
    private datePipe: DatePipe) {

    }

  ngOnInit(): void {
  }

  onDownloadTicket(){
    this.voucherService.getTicket(this.ticket.voucherId)
     .subscribe({
      next:(blob: Blob): void => {
        const file = new Blob([blob], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank', 'width=1000, height=800');
      },
       error: (errorResponse: any)=>{
        
       }
      })
   }

}

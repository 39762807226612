import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductStoreModel } from '../models/product/productStore.model';
import { ProductModel } from '../models/product/product.model';

declare function myFunction() : void;
declare function mobileCart(): void;
declare function screenTop() : void;


@Injectable({
  providedIn: 'root'
})
export class StoreService {
  public cartProducts: Array<ProductStoreModel> = [];
  public wishlistProducts: Array<ProductStoreModel> = [];
  public count:number;
  public total:number;
  public totalTax:number;
  public addQuantity:number;
  public removeQuantity:number;
  public alerts:any;
  public closeAlert:any;


  constructor(private toastr: ToastrService) {
    this.cartProducts = this.getProductFromCart();
    this.wishlistProducts = this.getProductFromWishlist();
  }

  calculateTotal() {
    let pTotal = 0;
    this.getProductFromCart().forEach((element : any) => {
      let prod = element.product;
      let p = 0;
      if (prod == null) {

      }
      else {
        let p = element.quantity * prod.sellingPrice.amount;
        pTotal = pTotal + p;
      }
    });
    this.total = pTotal;

    return pTotal;
  }

  calculateTax() {
    this.totalTax = this.total / 1.15;
    return this.total - this.totalTax;
  }


  getTableData() {

    if (localStorage.getItem('tableInfo') == null) {
      return null;
    }
    else {
      var strDate = localStorage.getItem('tableInfoDate')
      if (strDate) {
        var lastDate = new Date(Number(strDate)).getTime();
        if (isNaN(lastDate)) {
          return null;
        }
        if (lastDate < Date.now() - (5 * 60 * 1000)) {
          return null;
        }
      }
      else {
        return null;
      }
      var strVal = localStorage.getItem('tableInfo')
      return strVal;
    }
  }

  setTableData(tableInfo: string) {
    localStorage.setItem('tableInfoDate', Date.now().toString());
    localStorage.setItem('tableInfo', tableInfo);
  }

  getCartCount() {
    return this.cartProducts.length
  }

  getWishlistCount() {
    return this.wishlistProducts.length
  }

  getProductFromCart() {
    if (localStorage.getItem('cartProducts') == null) {
      return [];
    }
    else
      var strVal = localStorage.getItem('cartProducts')??"";
    return JSON.parse(strVal);
  }


  addProductToCart(product: ProductModel) {
    let count = 0;
    this.cartProducts.forEach(element => {

      console.log(element);

      if (product.productId == element.product.productId) {
        count++;
      }


    });
    if (count == 0) {
      let psm = new ProductStoreModel();
      psm.product = product;
      psm.quantity = 1;
      this.cartProducts.push(psm);
      this.toast("Product added to cart successfully")
      this.updateCartProduct()
    }
    else {
      this.toast("Product already in cart")
    }
    myFunction();
    mobileCart();
    screenTop();
  }


  updateCartProduct() {
    localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
  }


  removeProductFromCart(product: ProductStoreModel) {
    //return  localStorage.removeItem('cartProducts');
    let count = 0;
    this.cartProducts.forEach((element) => {
      if (product.product.productId == element.product.productId) {
        this.cartProducts.splice(0, 1);
        count++;
      }
    });

    if (count != 0) {
      this.toast("Product remove from cart")
      this.updateCartProduct()
    }
  }

  emptyCart() {
    //return  localStorage.removeItem('cartProducts');
    let count = 0;
    
    var tempCartProducts = [];
    this.cartProducts = []
    this.updateCartProduct()
  }

  removeOrderFromCart(results: any){
    var tetst = this.cartProducts;
  }


  getProductFromWishlist() {
    if (localStorage.getItem('wishlistProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('wishlistProducts')??"");
  }


  addProductToWishlist(product: ProductModel) {
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (product.productId == element.product.productId) {
        count++;
      }
    });
    if (count == 0) {
      let psm = new ProductStoreModel();
      psm.product = product;
      psm.quantity = 1;
      this.wishlistProducts.push(psm);
      this.toast("Product added to wishlist successfully")
      this.updateWishlistProduct()
    }
    else {
      this.toast("Product already in wishist")
    }
  }



  updateWishlistProduct() {
    localStorage.setItem("wishlistProducts", JSON.stringify(this.wishlistProducts));
  }

  removeProductFromWishlist(product: any) {
    let count = 0;
    console.log(product);
    this.wishlistProducts.forEach((element) => {
      if ((product.productId == undefined) || (product.productId == element.product.productId)) {
        this.wishlistProducts.splice(0, 1);
        count--;
      }
    });
    if (count != 0) {
      this.toast("Product remove from wishist")
      this.updateWishlistProduct()
    }
  }


  onAddQuantity(product: any) {
    this.cartProducts.forEach((element, index) => {
      if (product.product.productId == element.product.productId) {
        element.quantity++;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.product.productId == element.product.productId) {
        element.quantity++;
      }
    });

    this.updateWishlistProduct()
  }


  onRemoveQuantity(product: any) {
    this.cartProducts.forEach((element, index) => {
      if (product.product.productId == element.product.productId) {
        element.quantity--;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.product.productId == element.product.productId) {
        element.quantity--;
      }
    });

    this.updateWishlistProduct()
  }

  toast(msg: string) {
    this.toastr.success(msg);
  }

  acceptCookie() {
    localStorage.setItem('acceptCookie', "true");
  }

  acceptedCookie(): boolean {
    return localStorage.getItem('acceptCookie') == "true";
  }

  setCurrentPage(value: string): void {
    localStorage.setItem('currentPage', value);
  }

  setCurrentToken(value: string): void {
    localStorage.setItem('currentToken', value);
  }

  getCurrentToken(): string {
    return localStorage.getItem('currentToken')??"";
  }

  setCurrentRoles(value: string): void {
    localStorage.setItem('currentRoles', value);
  }

  getCurrentRoles(): string {
    return localStorage.getItem('currentRoles')??"";
  }

  logOut() {
    this.setCurrentPage("");
    this.setCurrentRoles("");
    this.setCurrentToken("");
  }

}

import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { environment } from '@env/environment';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { IveriComponent } from '../iveri/iveri.component';
//import { Store } from '@ngxs/store';

@Component({
  selector: 'app-iveri-modal',
  templateUrl: './iveri-modal.component.html',
  styleUrls: ['./iveri-modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class IveriModalComponent {

  public active = 'select';
  public closeResult: string;
  public modalOpen: boolean = false;
  public spinner
  modal: NgbModalRef;
  iveri: IveriComponent = null;
  private portal: any;
  private mercAppId: any;
  private accountNo: any; 
  private email: any;
  private order: any;
  private amount: any;

  @ViewChild("iveriModal", { static: false }) IveriModal: TemplateRef<string>;
  
  @Output() iveriSuccess: EventEmitter<string> = new EventEmitter();
  @Output() iveriFail: EventEmitter<string> = new EventEmitter();

  constructor(
    //private store: Store, 
    private modalService: NgbModal) {
  }

  async openModal(portal: any, mercAppId: any, accountNo: any, email: any, order: any, amount: any) {
    this.portal = portal;
    this.mercAppId = mercAppId;
    this.accountNo = accountNo;
    this.email = email;
    this.order = order;
    this.amount= amount;
    this.modalOpen = true;
    this.modal = this.modalService.open(this.IveriModal, {
      ariaLabelledBy: 'Iveri-Modal',
      centered: true,
      size: 'iverisize',
      windowClass: 'iveri-modal'
    });
    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }


  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  
  onIveriInit(event){
    this.iveri = event;
    this.iveri.postToIveri(this.portal, this.mercAppId, this.accountNo, this.email, this.order, this.amount);
  }


  onIveriSuccess(result : string){
    this.iveriSuccess.emit(result);
    this.modal.dismiss('close');
  }

  onIveriFail(result : string){
    this.iveriFail.emit(result);
    this.modal.dismiss('close');
  }
  
}

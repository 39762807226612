import { UserModel } from "@app/core/models/user/user.model";

export class SelectUserLinkedAccountsAction {
    static readonly type = '[User Linked Accounts] Select';
    constructor(public data : UserModel[]) {}
 }
  
 export class GetUserLinkedAccountsAction {
    static readonly type = '[User Linked Accounts] Get User Linked Accounts';
    constructor() {}
 }

 export class SetUserPhoneStep1Action {
   static readonly type = '[USER] Set User Phone Step 1';
   constructor(public phoneNumber: string) {}
}

export class SetUserPhoneStep2Action {
   static readonly type = '[USER] Set User Phone Step 2';
   constructor(public pin: string) {}
}

export class SetPhoneStateAction {
   static readonly type = '[USER] Set Phone State';
   constructor(public state: string) {}
}

export class LoadUserAction {
   static readonly type = '[USER] Load';
   constructor(public userData: UserModel) {}
}


import { Component } from '@angular/core';

@Component({
  selector: 'app-vendor-footer',
  templateUrl: './vendor-footer.component.html',
  styleUrls: ['./vendor-footer.component.scss']
})
export class VendorFooterComponent {

}

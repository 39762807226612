import { Injectable } from "@angular/core";
import { UserService } from "@app/core/services/user.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, interval, map, mergeMap, of } from "rxjs";
import * as ClientActions from './actions';
import * as AuthActions from '../auth/actions';
import { AppStateInterface } from "@app/core/types/appState.interface";
import { Store } from "@ngrx/store";
import { ClientService } from "@app/core/services";


@Injectable()
export class ClientEffects {


    getClient$ = createEffect(() => this.actions$.pipe(
        ofType(ClientActions.getClient),
        mergeMap(() => {
            return this.clientService.getClientByLocalURI().pipe(
                map((data) => {
                    return ClientActions.getClientSuccess({ data })
                }
                ))
        }))
    );

    getClientLogout$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        mergeMap(() => {
            return this.clientService.getClientByLocalURI().pipe(
                map((data) => {
                    return ClientActions.getClientSuccess({ data })
                }
                ))
        }))
    );

    constructor(
        private store: Store<AppStateInterface>,
        private actions$: Actions,
        private clientService: ClientService) { }
}
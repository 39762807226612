import { POMenuOrderModel } from "@app/core/models/preorder-cart/pomenuorder.model";
import { MenuModel } from "@app/core/models/preorder/menu.model";
import { MenuOrderModel } from "@app/core/models/preorder/menuorder.model";
import { UserModel } from "@app/core/models/user/user.model";

export class SelectPreOrderMenusAction {
   static readonly type = '[Pre-Order] Select Menus';
   constructor(public data: Array<MenuModel>) { }
}

export class SelectPreOrderMenuAction {
   static readonly type = '[Pre-Order] Select Menu';
   constructor(public data: MenuModel) { }
}

export class SelectPreOrderUserAction {
   static readonly type = '[Pre-Order] Select User';
   constructor(public userId: string) { }
}

export class SelectPreOrderCartAction {
   static readonly type = '[Pre-Order] Select Cart';
   constructor(public data: Array<POMenuOrderModel>) { }
}

export class GetPreOrderMenusAction {
   static readonly type = '[Pre-Order] Get by vendor Id and user Id';
   constructor(public vendorId: number, public userId: string, public startDate: Date, public endDate: Date) { }
}

export class GetPreOrderCartAction {
   static readonly type = '[Pre-Order] Get by vendor Id and user Id';
   constructor(public userId: string) { }
}
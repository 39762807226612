<app-task-card class="vw-100 bg-info bg-gradient" >
  <div class="card" style="width: 100%; min-width: 100%; background-color: orangered; border-style: solid; border-color: black; border-width: 1px;">
   <div class="card-header" style="color:white; background-color: red">
      <h5 class="float-start" style="display: inline-block">{{task?.taskName}} Error</h5>
      <h5 class="float-end" style="display: inline-block">{{task?.amount | currency:'R'}}</h5>
   </div>
   <div class="card-body">
     <div class="row">
       <div class="col-4">
         <!-- <time datetime="2014-09-24" class="date-as-calendar inline-flex size1_25x">
           <span class="weekday">{{dayOfWeek}}</span>
           <span class="day">{{day}}</span>
           <span class="month">{{month}}</span>
           <span class="year">{{time}}</span>
         </time> -->
       </div>
       <div class="col-8">
         <h3 class="card-title text-left">{{message}}</h3>
       </div>
     </div>
     <div class="row">
       <div class="col">
         <div [innerHtml]="body" ></div>
       </div>
     </div>
     <div class="row">
       <div class="col">
         <button type="button" class="btn btn-danger" style="min-width: 100%; max-width: 100%;" (click)="onDismissClick()">Dismiss</button>
       </div>
     </div>
   </div>
 </div> 
</app-task-card>
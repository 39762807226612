import { AppStateInterface } from "@app/core/types/appState.interface";
import { createSelector } from "@ngrx/store";


export const authFeature  = (state: AppStateInterface) => state.auth;

export const isLoggedInSelector = createSelector(
    authFeature, 
    (state) => state.isAuthLoggedIn
);

export const loginFailSelector = createSelector(
    authFeature, 
    (state) => state.error
);

export const loginSuccessSelector = createSelector(
    authFeature, 
    (state) => state.isAuthLoggedIn
);

<div class="img-wrapper">
    <div class="lable-block" *ngIf="product.new"><span class="lable3">new</span></div>
    <div class="front">
        <a href="javascrip:void(0)" (click)="navigateWithCustomData(product)">
            <img [defaultImage]="'assets/images/product/placeholder.jpg'"
            [lazyLoad]="product.images[0]?.src"
            class="img-fluid"
            alt="{{ product.images[0]?.alt }}">       
        </a>
    </div>
    <div class="cart-box">
        <button title="Add to cart" (click)="addToCart(product)">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="product-info">
    <br />
    <a href="javascrip:void(0)" (click)="navigateWithCustomData(product)">
    	<h6>{{ product?.title | titlecase }}</h6>
    </a>
    <h4>{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h4>
</div>

<!-- <app-cart-variation [direction]="'right'"></app-cart-variation> -->
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<!-- <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="!cartModal"></app-cart-modal> -->
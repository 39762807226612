<ng-template class="theme-modal" #qrCode let-modal>
	<div class="modal-content">
	  <div class="modal-header">
	    <h5 class="modal-title" id="exampleModalLabel">Present the QR code! {{timeLeft}}</h5>
	    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div class="modal-body center">
      <qrcode
        [qrdata]="qrData"
        [allowEmptyString]="true"
        [ariaLabel]="'QR Code image with the following content...'"
        [cssClass]="'center'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [margin]="4"
        [scale]="1"
        [title]="'Allxs QR Code'"
        [width]="380">
      </qrcode>
	   </div>
	</div>
</ng-template>
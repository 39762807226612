import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserModel } from "@app/core/models/user/user.model";
import { UserService } from "@app/core/services";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { GetUserLinkedAccountsAction, LoadUserAction, SelectUserLinkedAccountsAction, SetPhoneStateAction, SetUserPhoneStep1Action, SetUserPhoneStep2Action } from "../actions/user.action";
import { tap } from "rxjs";

export class UserStateModel {
  public linkedAccounts: UserModel[];
  public currentUser: UserModel;
  public phoneState: string;
  public otpToken: string;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    linkedAccounts: null,
    currentUser: null,
    phoneState: "INVALID",
    otpToken: "",
  }
})

@Injectable()
export class UserState {

  constructor(
    private _store: Store,
    private _userService: UserService,
    private _router: Router) {
  }

  @Selector()
  public static selectedLinkedAccounts(state: UserStateModel) {
    return state.linkedAccounts;
  }

  @Selector()
  public static currentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static phoneState(state: UserStateModel) {
    return state.phoneState;
  }

  @Action(SelectUserLinkedAccountsAction)
  public selectLinkedAccountsAction(ctx: StateContext<UserStateModel>, action: SelectUserLinkedAccountsAction) {
    ctx.patchState({ linkedAccounts: action.data });
  }

  @Action(LoadUserAction)
  public loadUserAction(ctx: StateContext<UserStateModel>, action: LoadUserAction) {
    ctx.patchState({ phoneState: action.userData.phoneVerified ? "VERIFIED" : "INVALID" })
    ctx.patchState({ currentUser: action.userData });
  }

  @Action(GetUserLinkedAccountsAction)
  public getLinkedAccountsAction(ctx: StateContext<UserStateModel>, action: GetUserLinkedAccountsAction) {
    return this._userService.fetchLinkedAccounts().subscribe(data => {
      ctx.patchState({ linkedAccounts: data });
    })
  }

  @Action(SetPhoneStateAction)
  public setPhoneStateAction(ctx: StateContext<UserStateModel>, action: SetPhoneStateAction) {
    ctx.patchState({ phoneState: action.state })
  }

  @Action(SetUserPhoneStep1Action)
  public setUserPhoneAction(ctx: StateContext<UserStateModel>, action: SetUserPhoneStep1Action) {
    return this._userService.setUserPhone(action.phoneNumber).pipe(
      tap({
        next: (data: any) => {
          this._store.dispatch(new SetPhoneStateAction("OTP"))
          ctx.patchState({ otpToken: data.token })
        },
        error: (errorResponse: any) => {
          this._store.dispatch(new SetPhoneStateAction("VALID"));
        }
      })
    )
  }

  @Action(SetUserPhoneStep2Action)
  public setUserPhone2Action(ctx: StateContext<UserStateModel>, action: SetUserPhoneStep2Action) {
    let state = ctx.getState();
    return this._userService.setUserOTP(state.otpToken, action.pin).pipe(
      tap({
        next: (data: any) => {
          this._userService.fetchUser();
          this._store.dispatch(new SetPhoneStateAction("VALID"));
        },
        error: (errorResponse: any) => {
          this._store.dispatch(new SetPhoneStateAction("VALID"));
        }
      })
    )
  }



}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { POMenuOrderModel } from "@app/core/models/preorder-cart/pomenuorder.model";
import { MenuModel } from "@app/core/models/preorder/menu.model";
import { PreorderService, VendorService } from "@app/core/services";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { GetPreOrderCartAction, GetPreOrderMenusAction, SelectPreOrderMenuAction, SelectPreOrderMenusAction, SelectPreOrderUserAction } from "../actions/preorder.action";

export class PreOrderStateModel {
    public menus: Array<MenuModel>;
    public selectedMenu: MenuModel;
    public userId: string;
    public preorderCart: Array<POMenuOrderModel>;
}

@State<PreOrderStateModel>({
    name: 'preorder',
    defaults: {
        menus: null,
        selectedMenu: null,
        userId: '',
        preorderCart: null
    }
})

@Injectable()
export class PreOrderState {

    constructor(
        private _store: Store,
        private _preorderService: PreorderService,
        private _vendorService: VendorService,
        private _router: Router) {
    }

    @Selector()
    public static menus(state: PreOrderStateModel) {
        return state.menus;
    }

    @Selector()
    public static selectedMenu(state: PreOrderStateModel) {
        return state.selectedMenu;
    }

    @Selector()
    public static selectedUser(state: PreOrderStateModel) {
        return state.userId;
    }

    @Selector()
    public static preorderCart(state: PreOrderStateModel) {
        return state.preorderCart;
    }

    @Action(SelectPreOrderMenusAction)
    public selectedMenusAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderMenusAction) {
      ctx.patchState({ menus: action.data });
    }

    @Action(SelectPreOrderMenuAction)
    public selectedMenuAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderMenuAction) {
      ctx.patchState({ selectedMenu: action.data });
      this._router.navigate(["pre-orders/menu"]);
    }

    @Action(SelectPreOrderUserAction)
    public selectedUserAction(ctx: StateContext<PreOrderStateModel>, action: SelectPreOrderUserAction) {
      ctx.patchState({ userId: action.userId });
    }

    @Action(GetPreOrderMenusAction)
    public getMenusAction(ctx: StateContext<PreOrderStateModel>, action: GetPreOrderMenusAction) {
      return this._vendorService.getMenus(action.vendorId, action.userId, action.startDate, action.endDate).subscribe(data => {
        ctx.patchState({ menus: data });
      })
    }

    @Action(GetPreOrderCartAction)
    public getPreOrderCartAction(ctx: StateContext<PreOrderStateModel>, action: GetPreOrderCartAction) {
      return this._preorderService.getCart(action.userId).subscribe(data => {
        ctx.patchState({ preorderCart: data });
      })
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '@app/shared/classes/product';
import { NewProductSlider } from '@app/shared/data/slider';
import { ProductService } from '@app/shared/services/product.service';

@Component({
  selector: 'app-event-item-box-vertical-slider',
  templateUrl: './event-item-box-vertical-slider.component.html',
  styleUrls: ['./event-item-box-vertical-slider.component.scss']
})
export class EventItemBoxVerticalSliderComponent implements OnInit {

  public title: string = 'Available Tickets';
  @Output() ticketSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() tickets : Product[] = [];
  public selectedTicket: Product | null = null; 

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService) { 
  }

  ngOnInit(): void {
    if (this.tickets.length === 1) {
      this.selectTicket(this.tickets[0]); // Select the first ticket by default
    }

    console.log(this.tickets);
  }

  selectTicket(ticket: any) {
    this.selectedTicket = ticket;
    this.ticketSelected.emit(ticket); 
  }
}
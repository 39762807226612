import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';

import { services } from './services';
import { authReducers } from './store/auth/reducers';
import { clientReducers } from './store/client/reducers';
import { AuthEffects } from './store/auth/effects';
import { userReducers } from './store/user/reducers';
import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.inteceptor';
import { UserEffects } from './store/user/effects';
import { ClientEffects } from './store/client/effects';
import { PostEffects } from './store/posts/effects';
import { reducers } from './store/posts/reducers';
import { transactionReducers } from './store/transactions/reducers';
import { TransactionEffects } from './store/transactions/effect';

@NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('posts', reducers), 
      StoreModule.forFeature('auth', authReducers), 
      StoreModule.forFeature('user', userReducers), 
      StoreModule.forFeature('client', clientReducers), 
      StoreModule.forFeature('transaction', transactionReducers),
      EffectsModule.forFeature([
        PostEffects, 
        AuthEffects, 
        UserEffects, 
        ClientEffects,
        TransactionEffects]),
      //StoreModule.forRoot({count:reducers}),
    ],
    exports: [],
    providers: [
      ...services,
      AuthGuard,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }
    ]
  })
  

  export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
      throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
  
  }

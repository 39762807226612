import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Product } from '@app/shared/classes/product';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit, OnDestroy  {
  
  @ViewChild("qrCode", { static: false }) QRCode: TemplateRef<any>;
  @Input() timeLeft: string;
  @Input() qrData: string;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) {
     }

  ngOnInit(): void {
  }

  openModal() {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.QRCode, {
      ariaLabelledBy: 'QR-Code-Modal',
      centered: true,
      size: 'md',
      windowClass: 'QRCode',
      backdrop: 'static'

    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closeModal() {
    this.modal.dismiss('close');
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}

import { createReducer, on } from "@ngrx/store";
import { AuthStateInterface } from "../../types/auth/auth-state.interface";
import * as ClientActions from "./actions";
import * as AuthActions from "../auth/actions";
import { UserInterface } from "@app/core/types/user/user.interface";
import { UserStateInterface } from "@app/core/types/user/user-State.interface";
import { UserModel } from "@app/core/models/user/user.model";
import { ClientInterface } from "@app/core/types/client/client.interface";
import { ClientStateInterface } from "@app/core/types/client/client-state.interface";

export const initialClient: ClientInterface ={
}

export const clientInitialState: ClientStateInterface = {
    client: initialClient,
    error: "",
};

export const clientReducers = createReducer(
    clientInitialState, 
    on(ClientActions.getClient, (state) => ({ ...state})),
    on(ClientActions.getClientSuccess, (state, action) => ({ ...state, client: {clientData: action.data}}))
);
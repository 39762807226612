<div>
    <div class="page-wrapper">
        <app-admin-header (rightSidebarEvent)="rightSidebar($event)"></app-admin-header>
        <div class="page-body-wrapper">
            <div class="page-sidebar" [class.open]="navServices.collapseSidebar">
                <app-admin-sidebar></app-admin-sidebar>
            </div>
            <div class="page-body">
                <app-admin-breadcrumb></app-admin-breadcrumb>
                <main [@animateRoute]="getRouterOutletState(o)">
                    <router-outlet #o="outlet"></router-outlet>
                </main>
            </div>
            <footer class="footer">
                <app-admin-footer></app-admin-footer>
            </footer>
        </div>
    </div>
</div>
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container *ngFor="let ticket of tickets | slice:0:3 ">
      <ng-template carouselSlide>
        <div>
          <div class="d-flex align-items-center event-item-detail-block" *ngFor="let ticket of tickets | slice:0:3"
          [ngClass]="{'selected': selectedTicket === ticket}" (click)="selectTicket(ticket)">
            <a>
              <img class="img-fluid w-auto"
                   [defaultImage]="'assets/images/product/placeholder.jpg'" 
                   [lazyLoad]="ticket.images[0].src" alt="">
            </a>
            <div class="media-body align-self-center event-body">
              <a>
                <h6>{{ ticket.title | titlecase }}</h6>
              </a>
              <h4>
                {{ ticket.price | currency: productService.Currency.currency!:'symbol' }}
              </h4>
              <h5 class="avalibility"
                *ngIf="ticket?.inventory?.isInventoryControlled && ticket.inventory?.quantityOnHand <= 0">SOLD OUT!!
              </h5>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div>
          <div class="d-flex align-items-center event-item-detail-block" *ngFor="let ticket of tickets | slice:3:6"
          [ngClass]="{'selected': selectedTicket === ticket}" (click)="selectTicket(ticket)">
            <a>
              <img class="img-fluid w-auto"
                   [defaultImage]="'assets/images/product/placeholder.jpg'" 
                   [lazyLoad]="ticket.images[0].src" alt="">
            </a>
            <div class="media-body align-self-center event-body">
              <a>
                <h6>{{ ticket.title | titlecase }}</h6>
              </a>
              <h4>
                {{ ticket.price | currency: productService.Currency.currency!:'symbol' }}
              </h4>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
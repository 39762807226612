import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SetUserPhoneStep2Action } from 'src/@allxs/store/actions/user.action';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements OnInit {

  @ViewChild("otpModal", { static: false }) OtpModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  form: UntypedFormGroup;
  phoneForm: UntypedFormGroup;

  private subscription: Subscription = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private modalService: NgbModal,
    private _store: Store,
    private _formBuilder: UntypedFormBuilder) {
    this.form = this._formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]]
    })
  }

  ngOnInit(): void {
  }

  async openModal() {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.OtpModal, {
      ariaLabelledBy: 'top-modal',
      centered: true,
      size: 'md',
      windowClass: 'Top'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  submitOTP() {
    this._store.dispatch(new SetUserPhoneStep2Action(this.form.value["otp"]));
    this.modal.dismiss('close');
  }
}

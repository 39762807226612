import { createReducer, on } from "@ngrx/store";
import * as TransactionActions from "./actions";
import { TransactionInterface } from "@app/core/types/transaction/transaction.interface";
import { TransactionStateInterface } from "@app/core/types/transaction/transaction-state.interface";

export const initialTransactions: TransactionInterface ={
 
}

export const transactionInitialState: TransactionStateInterface = {
  transactionData: null
};

export const transactionReducers = createReducer(
    transactionInitialState, 
    on(TransactionActions.getStatementofBalance, (state) => ({ ...state})),
    on(TransactionActions.getStatementofBalanceSuccess, (state, action) => ({ ...state, transactionData: {statementBalanceData: action.data}}))
);
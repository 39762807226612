<h6 class="product-title">Event Countdown</h6>
<div class="timer">
    <p id="demo">
      <span>
        <span class="timer-num">{{ days }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">Days</span>
      </span>
      <span>
        <span class="timer-num">{{ hours }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">Hrs</span>
      </span>
      <span>
        <span class="timer-num">{{ minutes }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">Min</span>
      </span>
      <span>
        <span class="timer-num">{{ seconds }}</span>
        <span class="timer-cal">Sec</span>
      </span>
    </p>
</div>
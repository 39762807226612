<ng-template class="theme-modal" #topup let-modal>
	<div class="modal-content">
	  <div class="modal-header">
	    <h5 class="modal-title" id="exampleModalLabel">Bank Card Top-ups!</h5>
	    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div class="modal-body center">
        <p *ngIf="useEFT">Bank card top-ups are provided as a service on top of EFT deposits.</p>
        <br/>
        <p *ngIf="useEFT">Topping up your account using a bank card should be used in emergencies only for instant clearance. This carries a top-up fee of</p>
		<p>This carries a top-up fee of</p>
		<h4>{{ topupFee.toFixed(2) | currency:currency?.currency:'symbol' }}</h4>
        <p *ngIf="useEFT">Please use the EFT method to top up your account free of charge.</p>
        <br/>
        <p>Please confirm this additional amount.</p>
		<div class="text-center">
			<button class="btn btn-primary mx-2" (click)="onAcceptClick()">Accept</button>
			<button class="btn btn-secondary mx-2" (click)="onDeclineClick()">Decline</button>
			<button class="btn btn-secondary mx-2" (click)="onEFTClick()" *ngIf="useEFT">Use EFT</button>
		  </div>
	   </div>
	</div>
</ng-template>

<app-eft-modal #eftModal></app-eft-modal>
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { environment } from '@env/environment';
import { ClientService } from '@app/core/services';

@Component({
  selector: 'app-iveri',
  templateUrl: './iveri.component.html',
  styleUrls: ['./iveri.component.scss'],
})
export class IveriComponent implements AfterViewInit {

  updateTimer: any = null;
  updateTimerSubscription: Subscription;

  @Output() iveriSuccess: EventEmitter<string> = new EventEmitter();
  @Output() iveriFail: EventEmitter<string> = new EventEmitter();
  @Output() iveriInit: EventEmitter<IveriComponent> = new EventEmitter<IveriComponent>();

  constructor(private _clientService: ClientService) {
  }

  ngAfterViewInit(): void {
    this.iveriInit.emit(this);
  }


  postToIveri(portal: any, mercAppId: any, accountNo: any, email: any, orderId: any, amount: any) {
    var sendAmount = (amount + "").split(".")[0];
    console.log(portal + "/bankresult/passweborder", environment.nedbankAuthPage);
    this.post(environment.nedbankAuthPage,
      {
        Lite_Merchant_Applicationid: "{" + mercAppId + "}",
        Lite_Order_Amount: sendAmount,
        Lite_Website_Successful_url: portal + "/bankresult/passweborder",
        Lite_Website_Fail_url: portal + "/bankresult/failweborder",
        Lite_Website_TryLater_url: portal + "/bankresult/failweborder",
        Lite_Website_Error_url: portal + "/bankresult/failweborder",
        Lite_Order_LineItems_Product_1: accountNo,
        Lite_Order_LineItems_Quantity_1: 1,
        Lite_Order_LineItems_Amount_1: sendAmount,
        Lite_ConsumerOrderID_PreFix: "DML",
        Lite_Merchant_Trace: orderId,
        Ecom_BillTo_Online_Email: email,
        Ecom_Payment_Card_Protocols: "iVeri",
        Ecom_ConsumerOrderID: orderId,
        Ecom_TransactionComplete: "",
        txtEmail: email,
        Amount: "R" + sendAmount,
        btnsubmit: "Proceed"
      });
    this.startUpdateTimer(orderId)
  }


  post(path: any, parameters: any) {
    var form = $('<form id="testform" target="receiver" action="url-to-server-Page"></form>');
    form.attr("method", "post");
    form.attr("action", path);
    $.each(parameters, function (key, value) {
      var field = $('<input></input>');
      field.attr("type", "hidden");
      field.attr("name", key as any);
      field.attr("value", value);
      form.append(field);
    });
    try {
      $("#receiver").remove('#testform');
    } catch (e) {
      // console.log(e);
    }
    try {
      var height = $(window).height() ?? 0;
      $("#receiver").css('height', height * 0.9 | 0);
      $("#receiver").contents().scrollTop(999999);
    }
    catch (e) {
      alert(e);
    }
    $("#receiver").append(form);
    form.submit();


  }

  public startUpdateTimer(contract) {
    let that = this;
    if (this.updateTimer == null) {
      this.updateTimer = timer(6000, 6000);
      this.updateTimerSubscription = this.updateTimer.subscribe(x => {
        that._clientService.getClientTopupStatus(contract)
          .subscribe({
            next: (data: any) => {
              var resultData = JSON.parse(data);

              if (resultData.Result == "wait") {
                return;
              }

              if (resultData.Result == "OK") {
                this.iveriSuccess.emit(resultData);
              }
              else {
                this.iveriFail.emit(resultData);
              }
            },
            error: (errorResponse: any) => {
              this.iveriFail.emit(errorResponse);
            }
          })
      });
    }
  }


  ngOnDestroy() {
    if (this.updateTimerSubscription)
      this.updateTimerSubscription.unsubscribe();
  }

  onComplete(result: string) {
    this.updateTimerSubscription.unsubscribe();
    this.updateTimer = null;
    this.iveriSuccess.emit(result);
  }

  onFail(result: string) {
    this.updateTimerSubscription.unsubscribe();
    this.updateTimer = null;
    this.iveriFail.emit(result);
  }
}

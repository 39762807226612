import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TaskInterface } from 'src/@allxs/interface/task.interface';

@Component({
    selector     : 'allxs-task-error',
    templateUrl  : './taskerror.component.html',
    styleUrls    : ['./taskerror.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskErrorComponent
{
    @Input() task: TaskInterface;
    @Input() level: string;
    @Input() message: string;

    public body: string = "";

    @Output('onDismiss')  onDismiss = new EventEmitter<TaskInterface>();
    /**
     * Constructor
     */
    constructor()
    {
    }

    onDismissClick(){
        this.onDismiss.emit();
    }
}

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { delay, withLatestFrom, map, Observable, Subject, takeUntil, Subscription } from 'rxjs';
import { config } from './core/app.config';
import { ClientService } from './core/services';
import { postsSelector } from './core/store/selectors';
import { AppStateInterface } from './core/types/appState.interface';
import { PostInterface } from './core/types/post/post-interface.interface';
import * as ClientActions from './core/store/client/actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { clientSelector } from './core/store/client/selectors';
import { VendorModel } from './core/models/vendor/vendor.model';
import { Store as NewStore } from '@ngxs/store';
import { SelectVendorAction } from 'src/@allxs/store/actions/vendor.actions';
import { VendorStoreModel } from './core/models/vendor/vendor-store.model';
import { ThemeImage } from './core/models/client/themeimage.model';
import { NavService } from './shared/services/nav.service';
import { isLoggedInSelector } from './core/store/auth/selector';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CheckForTasksAction } from 'src/@allxs/store/actions/task.action';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  title = environment.title;
  isLoading: boolean = true;
  posts$: Observable<PostInterface[]>;

  public themeLogo: string;
  public clientId: number;
  public isLoggedIn: boolean = false;
  private isPayment = false;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private store: Store<AppStateInterface>,
    private clientService: ClientService,
    private titleService: Title,
    private newStore: NewStore,
    public navServices: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) {

      let params = this.router.events.subscribe(
        params => {
          if(params['url']?.includes('/whatsapppurchase') > 0)
             this.isPayment = true;
        }
      );

    if (isPlatformBrowser(this.platformId)) {
      translate.addLangs(['en', 'af']);
      if (localStorage.getItem('currency') === null || localStorage.getItem('currency') === undefined) {
        localStorage.setItem('currency', JSON.stringify(config.currencys[2]));
      }

      if (localStorage.getItem('lang') === null || localStorage.getItem('lang') === undefined) {

        localStorage.setItem('lang', 'en');
        translate.use('en');
        translate.setDefaultLang('en');
      } else {
        translate.setDefaultLang(localStorage.getItem('lang') as string);
      }
    }
  }

  ngOnInit(): void {
    let thisurl = this.router.url;
    let clientIdRetrieved = false;
    this.isLoading = true;

    this.spinner.show();

    this.clientService.clientSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != null) {
          this.isLoading = false;
          this.spinner.hide();

          if (data.clientName) {
            this.titleService.setTitle(data.clientName);
            this.customizeThemeColor(data.theme.ThemeColor);
          }
        }
      }, (error) => {
        this.isLoading = false;
        this.spinner.hide();
        console.error('Error fetching client data:', error);
      });

    this.store.dispatch(ClientActions.getClient());

    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(isLoggedInSelector)).subscribe(data => {
      this.isLoggedIn = data;
    }));

    this.subscription.add(this.store.pipe(select(clientSelector)).subscribe(data => {
      if (data) {
        if (data.vendors.length === 1 &&
          data.clientData.startPage.toLowerCase() !== 'dashboard') {
          let vendor = data.vendors[0];
          var vendorStoreModel = new VendorStoreModel();
          vendorStoreModel = vendor;

          this.customizeThemeColor(vendorStoreModel.vendorData.theme.ThemeColor);

          if (this.isLoggedIn) {
            this.navServices.updateMenuItems(true, true);
          } else {
            this.navServices.updateMenuItems(false, true);
          }

          this.newStore.dispatch(new SelectVendorAction(vendorStoreModel));
        } 
        else if (data.clientData.startPage.toLowerCase() == 'dashboard' ||
          (data.clientData.startPage.toLowerCase() == 'store' &&
            data.clientData.behindAuthWall)) {
              if (!this.isPayment)
                 this.router.navigate(['/home/auth/login']);
        }
        else if (data.clientId == 109 || data.clientId == 351) {
          this.router.navigate(['/home/events/landing']);
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  customizeThemeColor(colour: string) {
    document.documentElement.style.setProperty('--theme-deafult', colour);
    document.documentElement.style.setProperty('--primary-color', colour);
  }

  getBannersByTypeWithNames(
    banners: ThemeImage[],
    type: string
  ): { BannerName: string; Banner: ThemeImage }[] {
    const filteredByType = banners.filter(banner => banner.Type.toLowerCase() === type.toLowerCase());

    const limitedBanners = filteredByType.slice(0, 3);

    return limitedBanners.map(({ Name, ...rest }) => ({
      BannerName: Name,
      Banner: { Name, ...rest },
    }));
  }

  onTestMe(){
    this.newStore.dispatch(new CheckForTasksAction());
    //this.iveriModal.openModal("https://clientapidev.allxs.co.za","D0BC0860-24D5-44C2-915E-857DA37C0ABD","341","","b5164f18-81d1-4616-bcd3-d971195427dd", 1);
  }
}

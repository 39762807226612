import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import { TasksInterface } from "../interface/task.interface";

@Injectable({ providedIn: 'root' })
export class TaskService {
    constructor(private http: HttpClient) {

    }
   
    public checkForTask(): Observable<TasksInterface> {
        let that = this;
        return that.http.get<TasksInterface>(environment.apiportal + '/api/task/getmytasks')
    }

    public getTaskTask(taskId : string): Observable<TasksInterface> {
        let that = this;
        return that.http.get<TasksInterface>(environment.apiportal + '/api/task/getmytasks')
    }
}

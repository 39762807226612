import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

import { MainHeaderComponent } from "./header/main-header/main-header.component";
import { VendorHeaderComponent } from "./header/vendor-header/vendor-header.component";
import { DiscountPipe } from "./pipes/discount.pipe";
import { SettingsComponent } from './components/settings/settings.component';
import { MainFooterComponent } from './footer/main-footer/main-footer.component';
import { VendorFooterComponent } from './footer/vendor-footer/vendor-footer.component';
import { ItemBoxComponent } from './components/item-box/item-box.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { WalletFooterComponent } from './footer/wallet-footer/wallet-footer.component';
import { WalletHeaderComponent } from './header/wallet-header/wallet-header.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavService } from './services/nav.service';
import { TableService } from './services/table.service';
import { WINDOW_PROVIDERS } from './services/windows.service';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { EventsItemBoxComponent } from './components/events-item-box/events-item-box.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SocialComponent } from './components/social/social.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { ItemBoxVerticalSliderComponent } from './components/item-box-vertical-slider/item-box-vertical-slider.component';
import { MenuComponent } from './components/menu/menu.component';
import { EventQuickViewComponent } from './components/modal/event-quick-view/event-quick-view.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { QrCodeComponent } from './components/modal/qr-code/qr-code.component';
import { AcceptCookieComponent } from './components/accept-cookie/accept-cookie.component';
import { PaymentFormComponent } from './components/modal/payment-form/payment-form.component';
import { EventItemBoxVerticalSliderComponent } from './components/event-item-box-vertical-slider/event-item-box-vertical-slider.component';
import { TicketItemBoxComponent } from './components/ticket-item-box/ticket-item-box.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GridComponent } from './components/grid/grid.component';
import { EventDatesVerticalSliderComponent } from './components/event-dates-vertical-slider/event-dates-vertical-slider.component';
import { TopupModalComponent } from './components/modal/topup-modal/topup-modal.component';
import { PaginationEventsComponent } from './components/pagination-events/pagination-events.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { WalletBreadcrumbComponent } from './components/breadcrumbs/wallet-breadcrumb/wallet-breadcrumb.component';
import { EftModalComponent } from './components/modal/eft-modal/eft-modal.component';
import { DateService } from './services/date.service';
import { PreOrderUserModalComponent } from './components/modal/pre-order-user-modal/pre-order-user-modal.component';
import { MenuItemBoxComponent } from './components/menu-item-box/menu-item-box.component';
import { SkeletonBoxComponent } from './components/skeleton-box/skeleton-box.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { AdminHeaderComponent } from './header/admin-header/admin-header.component';
import { AdminFooterComponent } from './footer/admin-footer/admin-footer.component';
import { AdminBreadcrumbComponent } from './components/breadcrumbs/admin-breadcrumb/admin-breadcrumb.component';
import { AdminSidebarComponent } from './components/sidebars/admin-sidebar/admin-sidebar.component';
import { OtpModalComponent } from './components/modal/otp-modal/otp-modal.component';
import { LookupChildAccountModalComponent } from './components/modal/lookup-child-account-modal/lookup-child-account-modal.component';
import { PreOrderVendorModalComponent } from './components/modal/pre-order-vendor-modal/pre-order-vendor-modal.component';
import { TaskInformationModalComponent } from './components/modal/task-information-modal/task-information-modal.component';
import { TaskPaymentModalComponent } from './components/modal/task-payment-modal/task-payment-modal.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { AllxsModule } from 'src/@allxs/allxs.module';

@NgModule({
    declarations: [
      ToggleFullscreenDirective,
      NgbdSortableHeader,
      MainHeaderComponent,
      VendorHeaderComponent,
      SettingsComponent,
      MainFooterComponent,
      VendorFooterComponent,
      DiscountPipe,
      ItemBoxComponent,
      CartModalComponent,
      QuickViewComponent,
      CartVariationComponent,
      BreadcrumbComponent,
      WalletFooterComponent,
      WalletHeaderComponent,
      FeatherIconsComponent,
      SidebarComponent,
      EventsItemBoxComponent,
      CountdownComponent,
      CategoriesComponent,
      SocialComponent,
      SizeModalComponent,
      ItemBoxVerticalSliderComponent,
      MenuComponent,
      EventQuickViewComponent,
      LeftMenuComponent,
      QrCodeComponent,
      AcceptCookieComponent,
      PaymentFormComponent,
      EventItemBoxVerticalSliderComponent,
      TicketItemBoxComponent,
      PaginationComponent,
      GridComponent,
      EventDatesVerticalSliderComponent,
      TopupModalComponent,
      PaginationEventsComponent,
      ContentLayoutComponent,
      WalletBreadcrumbComponent,
      EftModalComponent,
      PreOrderUserModalComponent,
      MenuItemBoxComponent,
      SkeletonBoxComponent,
      AdminLayoutComponent,
      AdminHeaderComponent,
      AdminFooterComponent,
      AdminBreadcrumbComponent,
      AdminSidebarComponent,
      OtpModalComponent,
      LookupChildAccountModalComponent,
      PreOrderVendorModalComponent,
      TaskInformationModalComponent,
      TaskPaymentModalComponent,
      TaskDetailComponent
    ],
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      CarouselModule,
      BarRatingModule,
      LazyLoadImageModule,
      NgxSkeletonLoaderModule,
      TranslateModule,
      QRCodeModule,
      AllxsModule
    ],
    providers: [NavService, TableService, DateService, WINDOW_PROVIDERS],
    exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      CarouselModule,
      BarRatingModule,
      LazyLoadImageModule,
      ToggleFullscreenDirective,
      NgxSkeletonLoaderModule,
      TranslateModule,
      NgbdSortableHeader,
      MainHeaderComponent,
      VendorHeaderComponent,
      MainFooterComponent,
      VendorFooterComponent,
      DiscountPipe,
      ItemBoxComponent,
      CartModalComponent,
      QuickViewComponent,
      CartVariationComponent,
      BreadcrumbComponent,
      WalletFooterComponent,
      WalletHeaderComponent,
      FeatherIconsComponent,
      SidebarComponent,
      EventsItemBoxComponent,
      CountdownComponent,
      CategoriesComponent,
      SocialComponent,
      SizeModalComponent,
      ItemBoxVerticalSliderComponent,
      MenuComponent,
      EventQuickViewComponent,
      LeftMenuComponent,
      QrCodeComponent,
      AcceptCookieComponent,
      PaymentFormComponent,
      EventItemBoxVerticalSliderComponent,
      TicketItemBoxComponent,
      PaginationComponent,
      GridComponent,
      EventDatesVerticalSliderComponent,
      TopupModalComponent,
      PaginationEventsComponent,
      ContentLayoutComponent,
      WalletBreadcrumbComponent,
      EftModalComponent,
      PreOrderUserModalComponent,
      PreOrderVendorModalComponent,
      MenuItemBoxComponent,
      AdminLayoutComponent,
      AdminHeaderComponent,
      AdminFooterComponent,
      AdminBreadcrumbComponent,
      AdminSidebarComponent,
      OtpModalComponent,
      LookupChildAccountModalComponent,
      TaskInformationModalComponent,
      TaskPaymentModalComponent,
      TaskDetailComponent
    ]
  })
  export class SharedModule { }
  
<div class="container-fluid">
    <br/>
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright 2023 All rights reserved.</p>
        </div>
        <div class="col-md-6">
            <p class="pull-right mb-0">Powered by Allxs Vip</p>
        </div>
    </div>
    <br/>
</div>
<div class="img-wrapper">
    <div class="front">
        <a href="javascrip:void(0)">
            <qrcode
            [qrdata]="ticket?.barcode"
            [allowEmptyString]="true"
            [ariaLabel]="'QR Code image with the following content...'"
            [cssClass]="'center'"
            [colorDark]="'#000000ff'"
            [colorLight]="'#ffffffff'"
            [elementType]="'canvas'"
            [errorCorrectionLevel]="'M'"
            [margin]="4"
            [scale]="1"
            [title]="'Allxs QR Code'"
            [width]="380">
          </qrcode>
        </a>
    </div>
    <div class="cart-box">
        <button (click)="onDownloadTicket()">
            <h4>Download</h4>
        </button>
    </div>
</div>
<div class="product-info">
    <br/>
    <a href="javascrip:void(0)">
    	<h4>{{ ticket?.voucherName | titlecase }}</h4>
    </a>
    <!-- <br/> -->
    <h5>{{ ticket?.barcode }}</h5>
</div>
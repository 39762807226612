import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { select, Store } from '@ngrx/store';
import { isLoggedInSelector } from '@app/core/store/auth/selector';
import { AppStateInterface } from '@app/core/types/appState.interface';
import { NavigationExtras, Router } from '@angular/router';
import { AuthorizedUser } from '@app/shared/classes/authorized';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;

  private subscription: Subscription;
  public isLoggedIn: boolean = false;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private router: Router,
    private store: Store<AppStateInterface>) {
    this.productService.cartItems.subscribe(response => this.products = response);
    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(isLoggedInSelector)).subscribe(data => {
      this.isLoggedIn = data;
    }));
  }

  ngOnInit(): void {
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code: any){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  onCheckOut() {
    this.router.navigate(['/home/account/checkout'])
    .catch(error => console.log('Navigation error:', error));
    // if (this.isLoggedIn) {
    //   this.router.navigate(['/home/account/checkout'])
    //   .catch(error => console.log('Navigation error:', error));
    // } else {
    //   let authorizedUser: AuthorizedUser = {
    //     redirectToCheckout: true
    //   }
    //   const navigationExtras: NavigationExtras = {
    //     state: { data: authorizedUser }
    //   };
      
    //   this.router.navigate(['/home/auth/login'], navigationExtras)
    //   .catch(error => console.log('Navigation error:', error));     
    // }
  }

}
